import { useContext, useState, useEffect, ChangeEvent } from 'react';
import { useQuery, useMutation } from 'urql';
import { useForm } from 'react-hook-form';
import { useParams, useHistory, Link } from 'react-router-dom';
import ReactQuill from 'react-quill';

import { LoadingContext } from '../../contexts/LoadingProvider';

import { Notification } from '../../notification';

import { Layout } from '../../components/_layout';

import { Select } from '../../components/select';
import { ReactComponent as Chevron } from '../../assets/left-chevron.svg';

import 'react-quill/dist/quill.snow.css';

const GETREGIONS = `query { regions }`;
const GETPROVINCES = `query($region: String!) { 
    provinces(region: $region) { 
        name code 
    } 
}`;

const GETCITIES = `query($provinceCode: String!) {
    cities(provinceCode: $provinceCode) {
        name
    }
}`;

const GETCATEGORIES = `query {
    categories {
        edges {
            slug
            name
        }
    }
}`;

const GETCOMPANY = `query($id: ID!) {
    companyADView(id: $id) {
        name
        owner
        cap
        address
        city
        province
        region
        email
        phone
        category {
            slug
        }
        description
        visible
        image {
            url
        }
        draftId
    }
}`;

export const ManageCompany: React.FC = () => {
    const { setIsLoading } = useContext(LoadingContext);
    const {
        register,
        handleSubmit,
        setValue,
        watch,
        formState: { errors }
    } = useForm();
    const history = useHistory();

    let { id } = useParams<{ id: string }>();

    const [region, setRegion] = useState<string>();
    const [provinceCode, setProvinceCode] = useState<string>();

    const [regions] = useQuery({ query: GETREGIONS });
    const [provinces] = useQuery({ query: GETPROVINCES, variables: { region }, pause: !region });
    const [cities] = useQuery({ query: GETCITIES, variables: { provinceCode }, pause: !provinceCode });
    const [categories] = useQuery({ query: GETCATEGORIES });
    const [company] = useQuery({ query: GETCOMPANY, variables: { id }, pause: !id });

    const [description, setDescription] = useState<string>();

    const [isVisible, setIsVisible] = useState(false);

    const [image, setImage] = useState<string>();

    const [wCap, wAddress, wRegion, wProvince, wCity] = watch(['cap', 'address', 'region', 'province', 'city']);

    useEffect(() => {
        if (setIsLoading) {
            setIsLoading(regions.fetching || provinces.fetching || cities.fetching || categories.fetching || company.fetching);
        }
    }, [setIsLoading, regions.fetching, provinces.fetching, cities.fetching, categories.fetching, company.fetching]);

    useEffect(() => {
        setValue('description', description);
    }, [description, setValue]);

    useEffect(() => {
        if (company.data) {
            setValue('name', company.data.companyADView.name);
            setValue('owner', company.data.companyADView.owner);
            setValue('cap', company.data.companyADView.cap);
            setValue('address', company.data.companyADView.address);
            setRegion(company.data.companyADView.region);
            setValue('region', company.data.companyADView.region);
            setProvinceCode(company.data.companyADView.province);
            setTimeout(() => {
                setValue('province', company.data.companyADView.province);
                setValue('city', company.data.companyADView.city);
            }, 500);
            setValue('email', company.data.companyADView.email);
            setValue('phone', company.data.companyADView.phone);
            setValue('category', company.data.companyADView.category.slug);
            setDescription(company.data.companyADView.description);
            setValue('visible', company.data.companyADView.visible);
            setIsVisible(company.data.companyADView.visible);
            if (company.data.companyADView.image) {
                setImage(company.data.companyADView.image.url);
            }
        }
    }, [company.data, setValue]);

    const [, manageCompany] = useMutation(
        `mutation($id: ID, $input: CompanyInput!) {
            manageCompany(id: $id, input: $input)
        }`
    );

    const onSubmit = async (form: Record<string, string | number>): Promise<void> => {
        const { data, error } = await manageCompany({ id, input: form });

        if (data.manageCompany) {
            if (!id) {
                id = data.manageCompany;
                history.replace(`/companies/manage/${id}`);
            }

            Notification.success('Azienda salvata con successo');
        }

        if (error) {
            Notification.error(error);
        }
    };

    const [, addCompanyImage] = useMutation(
        `mutation($id: ID!, $file: Upload!) {
            addCompanyImage(id: $id, file: $file)
        }`
    );

    const uploadImage = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
        event.preventDefault();

        const file = event.target.files && event.target.files[0];

        const { data, error } = await addCompanyImage({ id, file });

        if (data.addCompanyImage) {
            setImage(data.addCompanyImage);
        }

        if (error) {
            Notification.error(error);
        }
    };

    const [, removeCompanyImage] = useMutation(
        `mutation($id: ID!) {
            removeCompanyImage(id: $id)
        }`
    );

    const removeImage = async (): Promise<void> => {
        const { data, error } = await removeCompanyImage({ id });

        if (data.removeCompanyImage) {
            setImage(undefined);
        }

        if (error) {
            Notification.error(error);
        }
    };

    if ((regions.error || provinces.error || cities.error || categories.error, company.error)) {
        Notification.error(regions.error || provinces.error || cities.error || categories.error || company.error);
        return <></>;
    }

    return (
        <Layout bodyClasses="p-4">
            <div className="flex items-center">
                <Link to="/companies" className="text-pcons-green text-3xl">
                    <Chevron className="fill-current h-8 w-8" />
                </Link>
                <h3 className="ml-4">{!id ? 'Crea nuova' : 'Modifica'} azienda</h3>
            </div>

            <div className="w-full xl:w-1/2 mx-auto flex flex-col md:flex-row">
                <form noValidate className="flex-1 p-4" onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-6">
                        <label className="label" htmlFor="name">
                            Nome Azienda
                        </label>
                        <input
                            type="text"
                            {...register('name', { required: "Il nome dell'azienda è obbligatorio" })}
                            className="input"
                            placeholder="Nome azienda"
                            aria-label="Nome azienda"
                        />
                        <p className="mt-2 text-red-500 text-xs italic">{errors.name && errors.name.message}</p>
                    </div>
                    <div className="mb-6">
                        <label className="label" htmlFor="owner">
                            Proprietario
                        </label>
                        <input
                            type="text"
                            {...register('owner', { required: 'Il proprietario è obbligatorio' })}
                            className="input"
                            placeholder="Proprietario"
                            aria-label="Proprietario"
                        />
                        <p className="mt-2 text-red-500 text-xs italic">{errors.name && errors.name.message}</p>
                    </div>
                    <div className="flex flex-col md:flex-row">
                        <div className="mb-6 md:w-32 md:mr-2">
                            <label className="label" htmlFor="cap">
                                CAP
                            </label>
                            <input
                                type="text"
                                {...register('cap', { required: 'Il CAP è obbligatorio' })}
                                className="input"
                                placeholder="Indirizzo"
                                aria-label="Indirizzo"
                            />
                            <p className="mt-2 text-red-500 text-xs italic">{errors.cap && errors.cap.message}</p>
                        </div>
                        <div className="flex-1">
                            <label className="label" htmlFor="address">
                                Indirizzo
                            </label>
                            <input
                                type="text"
                                {...register('address', { required: "L'indirizzo è obbligatorio" })}
                                className="input"
                                placeholder="Indirizzo"
                                aria-label="Indirizzo"
                            />
                            <p className="mt-2 text-red-500 text-xs italic">{errors.address && errors.address.message}</p>
                        </div>
                    </div>

                    <div className="mb-6">
                        <label className="label" htmlFor="region">
                            Regione
                        </label>
                        <Select
                            {...register('region', { required: 'La regione è obbligatoria' })}
                            aria-label="Regione"
                            onChange={event => {
                                setRegion(event.target.value);
                                setProvinceCode(undefined);
                                setValue('province', undefined);
                                setValue('city', undefined);
                            }}
                        >
                            <option value="">...</option>
                            {regions.data?.regions &&
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                regions.data?.regions.map((r: string) => (
                                    <option key={r} value={r}>
                                        {r}
                                    </option>
                                ))}
                        </Select>
                        <p className="mt-2 text-red-500 text-xs italic">{errors.region && errors.region.message}</p>
                    </div>

                    <div className="mb-6">
                        <label className="label" htmlFor="province">
                            Provincia
                        </label>
                        <Select
                            {...register('province', { required: 'La provincia è obbligatoria' })}
                            aria-label="Provincia"
                            onChange={event => {
                                setProvinceCode(event.target.value);
                                setValue('city', undefined);
                            }}
                        >
                            <option value="">...</option>
                            {provinces.data?.provinces &&
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                provinces.data?.provinces.map((p: any) => (
                                    <option key={p.code} value={p.code}>
                                        {p.name}
                                    </option>
                                ))}
                        </Select>
                        <p className="mt-2 text-red-500 text-xs italic">{errors.province && errors.province.message}</p>
                    </div>

                    <div>
                        <label className="label" htmlFor="city">
                            Città
                        </label>
                        <Select {...register('city', { required: 'La città è obbligatoria' })} aria-label="Città">
                            <option value="">...</option>
                            {cities.data?.cities &&
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                cities.data?.cities.map((c: any) => (
                                    <option key={c.name} value={c.name}>
                                        {c.name}
                                    </option>
                                ))}
                        </Select>
                        <p className="mt-2 text-red-500 text-xs italic">{errors.city && errors.city.message}</p>
                    </div>

                    <div className="mb-6 mt-1 text-right text-sm font-bold text-pcons-green">
                        {wAddress && wCap && wCity && wProvince && wRegion && (
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={'https://www.google.com/maps?q=' + encodeURI(`${wAddress}, ${wCap} ${wCity} (${wProvince}) ${wRegion}`)}
                            >
                                Verifica l&apos;indirizzo con Google Maps &raquo;
                            </a>
                        )}
                    </div>

                    <div className="mb-6">
                        <label className="label" htmlFor="email">
                            Email azienda
                        </label>
                        <input type="text" {...register('email')} className="input" placeholder="Email azienda" aria-label="Email azienda" />
                    </div>

                    <div className="mb-6">
                        <label className="label" htmlFor="phone">
                            Telefono
                        </label>
                        <input type="text" {...register('phone')} className="input" placeholder="Telefono" aria-label="Telefono" />
                    </div>

                    <div className="mb-6">
                        <label className="label" htmlFor="category">
                            Classe merceologica
                        </label>
                        <Select {...register('category', { required: 'La classe merceologica è obbligatoria' })} aria-label="Classe merceologica">
                            <option value="">...</option>
                            {categories.data?.categories &&
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                categories.data?.categories.edges.map((c: any) => (
                                    <option key={c.slug} value={c.slug}>
                                        {c.name}
                                    </option>
                                ))}
                        </Select>
                        <p className="mt-2 text-red-500 text-xs italic">{errors.category && errors.category.message}</p>
                    </div>

                    <div className="mb-6">
                        <label className="label" htmlFor="description">
                            Descrizione
                        </label>
                        <ReactQuill theme="snow" value={description} onChange={setDescription}>
                            <div className="bg-gray-200 text-gray-700" style={{ fontFamily: 'Montserrat, sans-serif', fontSize: '1.25rem' }} />
                        </ReactQuill>
                        <div className="hidden">
                            <input
                                type="text"
                                {...register('description', {
                                    validate: value => {
                                        return value.replace(/(<([^>]+)>)/gi, '').length >= 150;
                                    }
                                })}
                                className="input"
                            />
                        </div>
                        <p className="mt-2 text-red-500 text-xs italic">
                            {errors.description && 'La descrizione è obbligatoria e deve contenere almeno 150 caratteri'}
                        </p>
                    </div>

                    <div className="mb-6 flex">
                        <div className="toggle-container">
                            <input
                                type="checkbox"
                                {...register('visible')}
                                className="toggle"
                                onChange={(event: ChangeEvent<HTMLInputElement>): void => setIsVisible(event.target.checked)}
                            />
                            <div className="toggle-label" />
                        </div>
                        <label className="label">{isVisible ? 'Visibile' : 'Non visibile'}</label>
                    </div>

                    <button type="submit" className="button green w-full">
                        Salva
                    </button>
                </form>
                <div className="w-full md:w-1/3 lg:w-2/6 p-4">
                    <span className="label">Immagine</span>

                    {image ? (
                        <>
                            <div className="flex justify-center items-center">
                                <img src={image} alt="Immagine azienda" title="Immagine azienda" />
                            </div>
                            <button type="button" className="button red mt-4 w-full" onClick={removeImage}>
                                Elimina
                            </button>
                        </>
                    ) : (
                        <>
                            <input type="file" id="file" className="hidden" accept=".jpg,.png,.jpeg" onChange={uploadImage} />
                            <label
                                htmlFor="file"
                                className="mt-2 w-full h-64 border-4 border-dashed border-pcons-green rounded-lg flex items-center justify-center text-6xl text-pcons-green
                    cursor-pointer"
                            >
                                +
                            </label>
                        </>
                    )}
                </div>
            </div>
        </Layout>
    );
};
