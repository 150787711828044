import { useContext, useEffect } from 'react';
import { useMutation, useQuery } from 'urql';
import { useParams, Link, useHistory } from 'react-router-dom';

import { LoadingContext } from '../../contexts/LoadingProvider';

import { Notification } from '../../notification';

import { Layout } from '../../components/_layout';

import { ReactComponent as Chevron } from '../../assets/left-chevron.svg';

const GETDRAFT = `query($id: ID!) {
    getDraftByAdmin(id: $id) {
        name
        owner
        cap
        address
        city
        province
        region
        email
        phone
        category {
            name
        }
        description
    }
}`;

export const ManageDraft: React.FC = () => {
    const { setIsLoading } = useContext(LoadingContext);
    const history = useHistory();

    const { id } = useParams<{ id: string }>();

    const [draft] = useQuery({ query: GETDRAFT, variables: { id } });

    ConstantSourceNode;

    useEffect(() => {
        if (setIsLoading) {
            setIsLoading(draft.fetching);
        }
    }, [setIsLoading, draft.fetching]);

    const [, approveDraft] = useMutation(
        `mutation($id: ID!) {
            approveDraft(id: $id)
        }`
    );

    const approve = async () => {
        const { data, error } = await approveDraft({ id });

        if (data.approveDraft) {
            Notification.success('Modifiche approvate');
            history.replace('/companies');
        }

        if (error) {
            Notification.error(error);
        }
    };

    const [, deleteDraft] = useMutation(
        `mutation($id: ID!) {
            deleteDraft(id: $id)
        }`
    );

    const discard = async () => {
        const { data, error } = await deleteDraft({ id });

        if (data.deleteDraft) {
            Notification.success('Modifiche scartate');
            history.replace('/companies');
        }

        if (error) {
            Notification.error(error);
        }
    };

    if (draft.error) {
        Notification.error(draft.error);
        return <></>;
    }

    return (
        <Layout bodyClasses="p-4">
            <div className="flex items-center">
                <Link to="/companies" className="text-pcons-green text-3xl">
                    <Chevron className="fill-current h-8 w-8" />
                </Link>
                <h3 className="ml-4">{!id ? 'Crea nuova' : 'Modifica'} azienda</h3>
            </div>

            {draft && draft.data && (
                <div className="w-full xl:w-1/2 mx-auto flex flex-col">
                    <div className="mb-6">
                        <div className="label">Nome Azienda</div>
                        <div className="input">{draft.data.getDraftByAdmin.name}</div>
                    </div>
                    <div className="mb-6">
                        <div className="label">Proprietario</div>
                        <div className="input">{draft.data.getDraftByAdmin.owner}</div>
                    </div>
                    <div className="flex flex-col md:flex-row">
                        <div className="mb-6 md:w-32 md:mr-2">
                            <div className="label">CAP</div>
                            <div className="input">{draft.data.getDraftByAdmin.cap}</div>
                        </div>
                        <div className="flex-1">
                            <div className="label">Indirizzo</div>
                            <div className="input">{draft.data.getDraftByAdmin.address}</div>
                        </div>
                    </div>

                    <div className="mb-6 flex flex-col md:flex-row">
                        <div className="md:mr-2">
                            <div className="label">Regione</div>
                            <div className="input">{draft.data.getDraftByAdmin.region}</div>
                        </div>

                        <div className="md:mr-2">
                            <div className="label">Provincia</div>
                            <div className="input">{draft.data.getDraftByAdmin.province}</div>
                        </div>

                        <div className="md:flex-1">
                            <div className="label">Città</div>
                            <div className="input">{draft.data.getDraftByAdmin.city}</div>
                        </div>
                    </div>

                    <div className="mb-6">
                        <div className="label">Email</div>
                        <div className="input">{draft.data.getDraftByAdmin.email}</div>
                    </div>

                    <div className="mb-6">
                        <div className="label">Telefono</div>
                        <div className="input">{draft.data.getDraftByAdmin.phone}</div>
                    </div>

                    <div className="mb-6">
                        <div className="label">Classe merceologica</div>
                        <div className="input">{draft.data.getDraftByAdmin.category.name}</div>
                    </div>

                    <div className="mb-6">
                        <div className="label">Descrizione</div>
                        <div className="input" dangerouslySetInnerHTML={{ __html: `${draft.data.getDraftByAdmin.description}` }} />
                    </div>

                    <div className="flex flex-col md:flex-row">
                        <button type="button" className="button flex-1 green md:mr-2" onClick={() => approve()}>
                            Approva
                        </button>
                        <button type="button" className="button flex-1 red mt-4 md:mt-0" onClick={() => discard()}>
                            Scarta
                        </button>
                    </div>
                </div>
            )}
        </Layout>
    );
};
