export class TokenManager {
    private static AUTH_TOKEN = 'pcons-auth-token';

    public static getToken(): string | null {
        return localStorage.getItem(TokenManager.AUTH_TOKEN);
    }

    public static setToken = (token: string): void => localStorage.setItem(TokenManager.AUTH_TOKEN, token);

    public static deleteToken = (): void => localStorage.removeItem(TokenManager.AUTH_TOKEN);
}
