import { ReactNode, useEffect } from 'react';

import { ReactComponent as Close } from '../assets/close.svg';

type LayoutProps = {
    color: string;
    left: ReactNode;
    right: ReactNode;
    onClose(): void;
};

export const DashboardItem: React.FC<LayoutProps> = (props: LayoutProps) => {
    useEffect(() => {
        document.body.style.overflow = 'hidden';

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);

    return (
        <div className="fixed z-20 top-0 left-0 w-screen h-screen flex items-center justify-center" style={{ backgroundColor: 'rgba(0,0,0,0.30)' }}>
            <div className="relative w-full md:w-auto h-full md:h-auto flex flex-col md:flex-row dashboard-radius" style={{ backgroundColor: props.color }}>
                <button className="absolute right-0 top-0 p-2" onClick={props.onClose}>
                    <Close className="fill-current text-black" />
                </button>

                <div className="dashboard-item-left flex items-center justify-center">{props.left}</div>
                <div className="flex-1 bg-white dashboard-item-right overflow-y-auto">{props.right}</div>
            </div>
        </div>
    );
};
