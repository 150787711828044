import { useState, useEffect } from 'react';
import { RouteProps, Link } from 'react-router-dom';

import firebase from '../../../firebase';

export interface RegisterConfirmationProps extends RouteProps {
    code: string;
}

export const RegisterConfirmation: React.FC<RegisterConfirmationProps> = (props: RegisterConfirmationProps) => {
    const [showError, setShowError] = useState<boolean>(false);

    useEffect(() => {
        const applyCode = async (): Promise<void> => {
            try {
                await firebase.auth().applyActionCode(props.code);
            } catch (e) {
                setShowError(true);
            }
        };

        applyCode();
    }, [props.location, props.code]);

    return (
        <>
            {!showError ? (
                <div className="w-full xl:w-1/4 md:w-4/6">
                    <h3>La tua registrazione è ora completata</h3>
                    <div className="text-xl mt-6">
                        Ora puoi&nbsp;
                        <Link to="/auth/login" className="text-pcons-green underline">
                            procedere al login
                        </Link>
                    </div>
                </div>
            ) : (
                <>
                    <h3>Si è verificato un errore</h3>
                    <p className="text-xl">Si è verificato un errore nel confermare la tua registrazione. Prova più tardi o contattaci per ricevere supporto</p>
                </>
            )}
        </>
    );
};
