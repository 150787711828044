import { useContext, useEffect, useState } from 'react';
import { useQuery } from 'urql';
import { CSVLink } from 'react-csv';

import { LoadingContext } from '../../contexts/LoadingProvider';

import { Notification } from '../../notification';

import { Layout } from '../../components/_layout';

const GETCCOMPANIES = `
    query {
        companyADList(limit: 999) {
            edges {
                owner
                name
                cap
                address
                city
                province
                region
                category {
                    name
                }
                visible
            }
        }
    }
`;

export const Export: React.FC = () => {
    const { setIsLoading } = useContext(LoadingContext);
    const [pause, setPause] = useState(true);
    const [exportData, setExportData] = useState([]);

    const [{ data, fetching, error }] = useQuery({ query: GETCCOMPANIES, pause });

    const today = new Date();
    const fileName = `esportazione-${today.getDate().toString().padStart(2, '0')}-${(today.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${today.getFullYear()}.csv`;

    useEffect(() => {
        if (data && data.companyADList) {
            const companies = data.companyADList.edges;
            for (const c of companies) {
                delete c.__typename;
                c.category = c.category.name;
                c.visible = c.visible ? 'SI' : 'NO';
            }

            setExportData(companies);
        }
    }, [data]);

    useEffect(() => {
        if (setIsLoading) {
            setIsLoading(fetching);
        }
    }, [setIsLoading, fetching]);

    if (error) {
        Notification.error(error);
        return <></>;
    }

    return (
        <Layout bodyClasses="p-4">
            <h3>Esporta</h3>
            <p>In questa sezione è possibile esportare i dati delle aziende presenti nel database in formato CSV.</p>
            <div className="mt-8 p-4 bg-gray-200 rounded flex justify-center">
                {pause && (
                    <button className="button green" onClick={(): void => setPause(false)}>
                        Genera link per esportare il CSV
                    </button>
                )}

                {exportData.length > 0 && (
                    <div className="flex flex-col">
                        <p>
                            Il file <b>{fileName}</b> è pronto per essere scaricato.
                        </p>
                        <div className="mt-4 flex justify-center">
                            <CSVLink filename={fileName} data={exportData} className="button green">
                                Scarica il file
                            </CSVLink>
                        </div>
                    </div>
                )}
            </div>
        </Layout>
    );
};
