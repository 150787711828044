import { useContext, useEffect } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useQuery, useMutation } from 'urql';

import { LoadingContext } from '../../contexts/LoadingProvider';

import { Notification } from '../../notification';

import { Layout } from '../../components/_layout';

import { ReactComponent as Chevron } from '../../assets/left-chevron.svg';

const GETCATEGORY = `
    query($id: ID!) {
        categoryADView(id: $id) {
            name
            order
            description
        }
    }
`;

export const ManageCategory: React.FC = () => {
    const { setIsLoading } = useContext(LoadingContext);
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useForm();
    const history = useHistory();

    let { id } = useParams<{ id: string }>();

    const [{ data, fetching, error }] = useQuery({
        query: GETCATEGORY,
        variables: { id },
        pause: !id
    });

    useEffect(() => {
        if (setIsLoading) {
            setIsLoading(fetching);
        }
    }, [setIsLoading, fetching]);

    useEffect(() => {
        if (data && data.categoryADView) {
            setValue('name', data.categoryADView.name);
            setValue('order', data.categoryADView.order);
            setValue('description', data.categoryADView.description);
        }
    }, [data, setValue]);

    const [, manageCategory] = useMutation(
        `mutation($id: ID, $input: CategoryInput!) {
            manageCategory(id: $id, input: $input)
        }`
    );

    const onSubmit = async (form: Record<string, string | number>): Promise<void> => {
        form.order = parseInt(form.order as string);

        const { data, error } = await manageCategory({ id, input: form });

        if (data.manageCategory) {
            if (!id) {
                id = data.manageCategory;
                history.replace(`/categories/manage/${id}`);
            }

            Notification.success('Classe merceologica salvata con successo');
        }

        if (error) {
            Notification.error(error);
        }
    };

    if (error) {
        Notification.error(error);
        return <></>;
    }

    return (
        <Layout bodyClasses="p-4">
            <div className="flex items-center">
                <Link to="/categories" className="text-pcons-green text-3xl">
                    <Chevron className="fill-current h-8 w-8" />
                </Link>
                <h3 className="ml-4">{!id ? 'Crea nuova' : 'Modifica'} classe merceologica</h3>
            </div>
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
                <div className="h-full p-4 xl:p-8 flex flex-col items-center justify-center">
                    <div className="w-full xl:w-1/3 md:w-4/6">
                        <div className="mb-6">
                            <label className="label" htmlFor="name">
                                Nome classe
                            </label>
                            <input
                                type="text"
                                {...register('name', { required: 'Il nome della classe è obbligatorio' })}
                                className="input"
                                placeholder="Nome classe"
                                aria-label="Nome classe"
                            />
                            <p className="mt-2 text-red-500 text-xs italic">{errors.name && errors.name.message}</p>
                        </div>
                        <div className="mb-6">
                            <label className="label" htmlFor="order">
                                Ordinamento
                            </label>
                            <input
                                type="number"
                                {...register('order', {
                                    required: "L'ordinamento è obbligatorio e deve essere un numero maggiore o uguale a zero",
                                    min: { value: 0, message: 'Il valore minimo è 0' }
                                })}
                                className="input"
                                placeholder="#"
                                aria-label="#"
                                min="0"
                            />
                            <p className="mt-2 text-red-500 text-xs italic">{errors.order && errors.order.message}</p>
                            <small className="block mt-4">
                                L&apos;ordinamento permette di organizzare autonomamente come le classi merceologiche verranno visualizzate. Naturalmente queste
                                sono ordinate per nome, mentre grazie al campo &quot;ordinamento&quot; è possibile specificare come dovranno essere viste nelle
                                schermate che richiedono questa selezione.
                            </small>
                        </div>

                        <div className="mb-6">
                            <label className="label" htmlFor="description">
                                Descrizione (facoltativa)
                            </label>
                            <textarea {...register('description')} className="input" placeholder="Descrizione" aria-label="Descrizione"></textarea>
                        </div>

                        <button type="submit" className="button green w-full">
                            Salva
                        </button>
                    </div>
                </div>
            </form>
        </Layout>
    );
};
