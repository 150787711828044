import { useContext, useState, useEffect } from 'react';
import { useQuery, useMutation } from 'urql';
import { useForm } from 'react-hook-form';
import { useHistory, Link } from 'react-router-dom';
import ReactQuill from 'react-quill';

import { LoadingContext } from '../../contexts/LoadingProvider';

import { Notification } from '../../notification';

import { Layout } from '../../components/_layout';
import { Select } from '../../components/select';
import { ReactComponent as Chevron } from '../../assets/left-chevron.svg';

import 'react-quill/dist/quill.snow.css';

const GETREGIONS = `query { regions }`;
const GETPROVINCES = `query($region: String!) { 
    provinces(region: $region) { 
        name code 
    } 
}`;

const GETCITIES = `query($provinceCode: String!) {
    cities(provinceCode: $provinceCode) {
        name
    }
}`;

const GETCATEGORIES = `query {
    categories(limit: 999, orderBy: order_ASC) {
        edges {
            slug
            name
        }
    }
}`;

export const CreateUserCompany: React.FC = () => {
    const { setIsLoading } = useContext(LoadingContext);
    const {
        register,
        handleSubmit,
        setValue,
        watch,
        formState: { errors }
    } = useForm();
    const history = useHistory();

    const [region, setRegion] = useState<string>();
    const [provinceCode, setProvinceCode] = useState<string>();

    const [regions] = useQuery({ query: GETREGIONS });
    const [provinces] = useQuery({ query: GETPROVINCES, variables: { region }, pause: !region });
    const [cities] = useQuery({ query: GETCITIES, variables: { provinceCode }, pause: !provinceCode });
    const [categories] = useQuery({ query: GETCATEGORIES });

    const [description, setDescription] = useState<string>();

    const [wCap, wAddress, wRegion, wProvince, wCity] = watch(['cap', 'address', 'region', 'province', 'city']);

    useEffect(() => {
        if (setIsLoading) {
            setIsLoading(regions.fetching || provinces.fetching || cities.fetching || categories.fetching);
        }
    }, [setIsLoading, regions.fetching, provinces.fetching, cities.fetching, categories.fetching]);

    useEffect(() => {
        setValue('description', description);
    }, [description, setValue]);

    const [, createByOwner] = useMutation(
        `mutation($input: CompanyInput!) {
            createByOwner(input: $input)
        }`
    );

    const onSubmit = async (form: Record<string, string | number>): Promise<void> => {
        if (description) {
            form.description = description;
        }

        const { data, error } = await createByOwner({ input: form });

        if (data.createByOwner) {
            history.replace('/user/companies');

            Notification.success('Azienda creata con successo');
        }

        if (error) {
            Notification.error(error);
        }
    };

    if (regions.error || provinces.error || cities.error || categories.error) {
        Notification.error(regions.error || provinces.error || cities.error || categories.error);
        return <></>;
    }

    return (
        <Layout bodyClasses="p-4">
            <div className="flex items-center">
                <Link to="/user/companies" className="text-pcons-green text-3xl">
                    <Chevron className="fill-current h-8 w-8" />
                </Link>
                <h3 className="ml-4">Compila i dati e carica il logo</h3>
            </div>

            <div className="w-full xl:w-1/2 mx-auto flex flex-col md:flex-row">
                <form noValidate className="flex-1 p-4" onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-6">
                        <label className="label" htmlFor="name">
                            Nome Azienda
                        </label>
                        <input
                            type="text"
                            {...register('name', { required: "Il nome dell'azienda è obbligatorio" })}
                            className="input"
                            placeholder="Nome azienda"
                            aria-label="Nome azienda"
                        />
                        <p className="mt-2 text-red-500 text-xs italic">{errors.name && errors.name.message}</p>
                    </div>
                    <div className="flex flex-col md:flex-row">
                        <div className="mb-6 md:w-32 md:mr-2">
                            <label className="label" htmlFor="cap">
                                CAP
                            </label>
                            <input
                                type="text"
                                {...register('cap', { required: 'Il CAP è obbligatorio' })}
                                className="input"
                                placeholder="Indirizzo"
                                aria-label="Indirizzo"
                            />
                            <p className="mt-2 text-red-500 text-xs italic">{errors.cap && errors.cap.message}</p>
                        </div>
                        <div className="flex-1">
                            <label className="label" htmlFor="address">
                                Indirizzo
                            </label>
                            <input
                                type="text"
                                {...register('address', { required: "L'indirizzo è obbligatorio" })}
                                className="input"
                                placeholder="Indirizzo"
                                aria-label="Indirizzo"
                            />
                            <p className="mt-2 text-red-500 text-xs italic">{errors.address && errors.address.message}</p>
                        </div>
                    </div>

                    <div className="mb-6">
                        <label className="label" htmlFor="region">
                            Regione
                        </label>
                        <Select
                            {...register('region', { required: 'La regione è obbligatoria' })}
                            aria-label="Regione"
                            onChange={event => {
                                setRegion(event.target.value);
                                setProvinceCode(undefined);
                                setValue('province', undefined);
                                setValue('city', undefined);
                            }}
                        >
                            <option value="">...</option>
                            {regions.data?.regions &&
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                regions.data?.regions.map((r: string) => (
                                    <option key={r} value={r}>
                                        {r}
                                    </option>
                                ))}
                        </Select>
                        <p className="mt-2 text-red-500 text-xs italic">{errors.region && errors.region.message}</p>
                    </div>

                    <div className="mb-6">
                        <label className="label" htmlFor="province">
                            Provincia
                        </label>
                        <Select
                            {...register('province', { required: 'La provincia è obbligatoria' })}
                            aria-label="Provincia"
                            onChange={event => {
                                setProvinceCode(event.target.value);
                                setValue('city', undefined);
                            }}
                        >
                            <option value="">...</option>
                            {provinces.data?.provinces &&
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                provinces.data?.provinces.map((p: any) => (
                                    <option key={p.code} value={p.code}>
                                        {p.name}
                                    </option>
                                ))}
                        </Select>
                        <p className="mt-2 text-red-500 text-xs italic">{errors.province && errors.province.message}</p>
                    </div>

                    <div>
                        <label className="label" htmlFor="city">
                            Città
                        </label>
                        <Select {...register('city', { required: 'La città è obbligatoria' })} aria-label="Città">
                            <option value="">...</option>
                            {cities.data?.cities &&
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                cities.data?.cities.map((c: any) => (
                                    <option key={c.name} value={c.name}>
                                        {c.name}
                                    </option>
                                ))}
                        </Select>
                        <p className="mt-2 text-red-500 text-xs italic">{errors.city && errors.city.message}</p>
                    </div>
                    <div className="mb-6 mt-1 text-right text-sm font-bold text-pcons-green">
                        {wAddress && wCap && wCity && wProvince && wRegion && (
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={'https://www.google.com/maps?q=' + encodeURI(`${wAddress}, ${wCap} ${wCity} (${wProvince}) ${wRegion}`)}
                            >
                                Verifica l&apos;indirizzo con Google Maps &raquo;
                            </a>
                        )}
                    </div>

                    <div className="mb-6">
                        <label className="label" htmlFor="email">
                            Email azienda
                        </label>
                        <input type="text" {...register('email')} className="input" placeholder="Email azienda" aria-label="Email azienda" />
                    </div>

                    <div className="mb-6">
                        <label className="label" htmlFor="phone">
                            Telefono
                        </label>
                        <input type="text" {...register('phone')} className="input" placeholder="Telefono" aria-label="Telefono" />
                    </div>

                    <div className="mb-6">
                        <label className="label" htmlFor="category">
                            Classe merceologica
                        </label>
                        <Select {...register('category', { required: 'La classe merceologica è obbligatoria' })} aria-label="Classe merceologica">
                            <option value="">...</option>
                            {categories.data?.categories &&
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                categories.data?.categories.edges.map((c: any) => (
                                    <option key={c.slug} value={c.slug}>
                                        {c.name}
                                    </option>
                                ))}
                        </Select>
                        <p className="mt-2 text-red-500 text-xs italic">{errors.category && errors.category.message}</p>
                    </div>

                    <div className="mb-6">
                        <label className="label" htmlFor="description">
                            Descrizione
                        </label>
                        <ReactQuill theme="snow" value={description} onChange={setDescription}>
                            <div className="bg-gray-200 text-gray-700" style={{ fontFamily: 'Montserrat, sans-serif', fontSize: '1.25rem' }} />
                        </ReactQuill>
                        <div className="hidden">
                            <input
                                type="text"
                                {...register('description', {
                                    validate: value => {
                                        return value.replace(/(<([^>]+)>)/gi, '').length >= 150;
                                    }
                                })}
                                className="input"
                            />
                        </div>
                        <p className="mt-2 text-red-500 text-xs italic">
                            {errors.description && 'La descrizione è obbligatoria e deve contenere almeno 150 caratteri'}
                        </p>
                    </div>

                    <button type="submit" className="button green w-full">
                        Salva
                    </button>
                </form>
            </div>
        </Layout>
    );
};
