import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import { pipe, tap } from 'wonka';
import { createClient, Provider as GraphQLProvider, Exchange, dedupExchange, cacheExchange } from 'urql';
import { multipartFetchExchange } from '@urql/exchange-multipart-fetch';
import dayjs from 'dayjs';
import 'dayjs/locale/it';

import { AuthProvider } from './contexts/AuthProvider';
import { LoadingProvider } from './contexts/LoadingProvider';

import App from './App';
import { TokenManager } from './token';

dayjs.locale('it');

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const errorExchange: Exchange = ({ forward }) => ops$ => {
    return pipe(
        forward(ops$),
        tap(({ error }) => {
            if (error) {
                const errors = error.graphQLErrors.map(e => ({ code: e.extensions && e.extensions.code, message: e.message }));
                errors.forEach(e => {
                    if (e.code === 'UNAUTHENTICATED' || e.message === 'UNAUTHENTICATED') {
                        TokenManager.deleteToken();
                        window.location.href = `/auth/login?returnUrl=${encodeURI(window.location.pathname)}`;
                    }
                });
            }
        })
    );
};

const client = createClient({
    url: process.env.REACT_APP_BASE_URL as string,
    fetchOptions: () => {
        const token = TokenManager.getToken();
        return {
            headers: { authorization: token ? `Bearer ${token}` : '' }
        };
    },
    exchanges: [dedupExchange, cacheExchange, errorExchange, multipartFetchExchange]
});

ReactDOM.render(
    <StrictMode>
        <AuthProvider>
            <GraphQLProvider value={client}>
                <LoadingProvider>
                    <MessengerCustomerChat pageId="104146214265611" appId="203674171229576" language="it_IT" />
                    <App />
                </LoadingProvider>
            </GraphQLProvider>
        </AuthProvider>
    </StrictMode>,
    document.getElementById('root')
);
