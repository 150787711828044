import { useContext, useEffect, useMemo, useState } from 'react';
import { useQuery, useMutation } from 'urql';
import { Link } from 'react-router-dom';

import { LoadingContext } from '../../contexts/LoadingProvider';

import { Notification } from '../../notification';

import { Layout } from '../../components/_layout';
import { ReactComponent as Sort } from '../../assets/sort.svg';
import { ReactComponent as Edit } from '../../assets/edit.svg';
import { ReactComponent as TrashCan } from '../../assets/trash-can.svg';
import { ReactComponent as Megaphone } from '../../assets/megaphone.svg';

const GETCCOMPANIES = `
    query($limit: Int, $cursor: Int, $orderBy: CompanyOrderByInput) {
        companies(limit: $limit, cursor: $cursor, orderBy: $orderBy) {
            edges {
                id
                name
                cap
                region
                province
                city
                category {
                    name
                }
                visible
                draftId
            }
            pageInfo {
                hasNextPage
                endCursor
            }
        }
    }
`;

export const UserCompanies: React.FC = () => {
    const { setIsLoading } = useContext(LoadingContext);
    const [cursor, setCursor] = useState(0);
    const [orderBy, setOrderBy] = useState('name_ASC');

    const limit = 25;

    const variables = useMemo(
        () => ({
            limit,
            cursor,
            orderBy
        }),
        [cursor, orderBy]
    );

    const [{ data, fetching, error }, reexecuteQuery] = useQuery({ query: GETCCOMPANIES, variables, requestPolicy: 'cache-and-network' });

    const sort = (cur: number, order?: string): void => {
        setCursor(cur);
        setOrderBy(order || orderBy);
    };

    const [, deleteCompanyByOwner] = useMutation(
        `mutation($id: ID!) {
            deleteCompanyByOwner(id: $id)
        }`
    );

    const del = async (id: string): Promise<void> => {
        if (window.confirm("Sei sicuro di voler eliminare quest'azienda?")) {
            const { data, error } = await deleteCompanyByOwner({ id });

            if (data.deleteCompanyByOwner) {
                reexecuteQuery();

                Notification.success('Azienda eliminata con successo');
            }

            if (error) {
                Notification.error(error);
            }
        }
    };

    useEffect(() => {
        if (setIsLoading) {
            setIsLoading(fetching);
        }
    }, [setIsLoading, fetching]);

    if (error) {
        Notification.error(error);
        return <></>;
    }

    return (
        <Layout bodyClasses="p-4">
            <div className="flex flex-col lg:flex-row">
                <div className="flex-1 mr-4">
                    <h2>Benvenuto</h2>
                    <p className="text-2xl">nell&apos;area che ti consente di inserire la tua azienda (o più d&apos;una) e le opportunità che offre</p>
                    <p className="text-2xl font-bold mt-4">Solo 3 passaggi e sei in evidenza</p>
                </div>

                <div className="w-full lg:w-1/4 border-2 border-pcons-green rounded-lg px-8 py-4 text-2xl mt-4 lg:mt-8 lg:mr-4">
                    <h3>I tuoi step</h3>
                    <div className="mt-6 flex items-center">
                        <span className="mr-3 text-4xl text-pcons-green ">1</span>Inserisci la tua azienda
                    </div>
                    <div className="mt-4 flex items-center">
                        <span className="mr-3 text-4xl text-pcons-green ">2</span>Compila i dati e carica il logo
                    </div>
                    <div className="mt-4 flex items-center">
                        <span className="mr-3 text-4xl text-pcons-green ">3</span>Crea la tua offerta e carica la promo
                    </div>
                </div>

                <div className="w-full lg:w-1/4 border-2 border-pcons-green rounded-lg px-8 py-4 text-2xl mt-4 lg:mt-8 lg:mr-4">
                    <h3>Legenda</h3>
                    <div className="mt-6 flex items-center">
                        <Megaphone className="w-8 h-8 mr-3" /> Visualizza offerte inserite
                    </div>
                    <div className="mt-4 flex items-center">
                        <Edit className="w-8 h-8 mr-3" /> Modifica i dati azienda
                    </div>
                    <div className="mt-4 flex items-center">
                        <TrashCan className="w-8 h-8 mr-3" /> Elimina azienda
                    </div>
                </div>
            </div>

            <div className="mt-12">
                <Link to="/user/create" className="button green rounded-full text-2xl py-4 px-6">
                    Inserisci l&apos;azienda
                </Link>
            </div>

            <h4 className="mt-12 font-bold">Elenco aziende inserite:</h4>
            <div className="overflow-x-auto md:overflow-hidden p-1 mt-8">
                <table className="table-fixed min-w-full">
                    <thead>
                        <tr>
                            <td className="px-4 py-2 text-gray-600">
                                <button
                                    className="flex items-center font-bold"
                                    onClick={(): void => sort(cursor, orderBy === 'name_ASC' ? 'name_DESC' : 'name_ASC')}
                                >
                                    Nome <Sort className="fill-current ml-2 h-4" />
                                </button>
                            </td>
                            <td className="w-32 px-4 py-2 font-bold text-gray-600">CAP</td>
                            <td className="w-12 px-4 py-2 text-gray-600">
                                <button
                                    className="flex items-center font-bold"
                                    onClick={(): void => sort(cursor, orderBy === 'region_ASC' ? 'region_DESC' : 'region_ASC')}
                                >
                                    Regione <Sort className="fill-current ml-2 h-4" />
                                </button>
                            </td>
                            <td className="w-12 px-4 py-2 text-gray-600">
                                <button
                                    className="flex items-center font-bold"
                                    onClick={(): void => sort(cursor, orderBy === 'province_ASC' ? 'province_DESC' : 'province_ASC')}
                                >
                                    Prov. <Sort className="fill-current ml-2 h-4" />
                                </button>
                            </td>
                            <td className="w-12 px-4 py-2 text-gray-600">
                                <button
                                    className="flex items-center font-bold"
                                    onClick={(): void => sort(cursor, orderBy === 'city_ASC' ? 'city_DESC' : 'city_ASC')}
                                >
                                    Città <Sort className="fill-current ml-2 h-4" />
                                </button>
                            </td>
                            <td className="w-64 px-4 py-2 font-bold text-gray-600">Categoria</td>
                            <td style={{ width: '140px' }} />
                        </tr>
                    </thead>
                    <tbody>
                        {data && data.companies && data.companies.edges.length > 0 ? (
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            data.companies.edges.map((company: any) => (
                                <tr key={company.id}>
                                    <td className="border-b px-4 py-2">
                                        <div className="flex flex-col">
                                            <b>{company.name}</b>
                                            {!company.visible && <div className="text-sm italic text-red-600">Non visibile sul portale</div>}
                                            {company.draftId && <div className="text-sm italic">In fase di approvazione</div>}
                                        </div>
                                    </td>
                                    <td className="border-b px-4 py-2">{company.cap}</td>
                                    <td className="border-b px-4 py-2">{company.region}</td>
                                    <td className="border-b px-4 py-2">{company.province}</td>
                                    <td className="border-b px-4 py-2">{company.city}</td>
                                    <td className="border-b px-4 py-2">{company.category.name}</td>
                                    <td className="border-b px-4 py-2">
                                        <div className="flex items-center">
                                            {company.visible && (
                                                <>
                                                    <Link to={`/user/offers/${company.id}`} className="button bordered">
                                                        <Megaphone className="fill-current" title="Offerte" />
                                                    </Link>
                                                    <Link
                                                        to={`/user/draft/${company.id}/${company.draftId || ''}`}
                                                        className="button green mt-2 md:mt-0 md:ml-2"
                                                    >
                                                        <Edit className="fill-current text-white" title="Modifica" />
                                                    </Link>
                                                </>
                                            )}
                                            <button className="button red mt-2 md:mt-0 md:ml-2" onClick={(): Promise<void> => del(company.id)}>
                                                <TrashCan className="fill-current text-white" title="Elimina" />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td className="border px-4 py-2" colSpan={7}>
                                    Nessuna azienda inserita
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>

                <div className="mt-8 flex justify-center">
                    <div className="border border-gray-700 rounded flex">
                        {cursor && cursor > 0 ? (
                            <button className="block w-32 p-4 text-center font-semibold" onClick={(): void => sort(cursor - limit)}>
                                INDIETRO
                            </button>
                        ) : (
                            <></>
                        )}
                        {data && data.companies.pageInfo.hasNextPage && (
                            <button
                                className="even:border-l border-gray-700 block w-32 p-4 text-center font-semibold"
                                onClick={(): void => sort(data.companies.pageInfo.endCursor)}
                            >
                                AVANTI
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </Layout>
    );
};
