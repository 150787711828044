import { MouseEvent, ReactNode, useContext, useEffect, useState } from 'react';
import { useHistory, Link, useLocation } from 'react-router-dom';

import firebase from '../firebase';

import { TokenManager } from '../token';

import { AuthContext } from '../contexts/AuthProvider';

import logo from '../assets/logo.png';
import { ReactComponent as Hamburger } from '../assets/hamburger.svg';

type LayoutProps = {
    bodyClasses?: string;
    children: ReactNode;
};

export const Layout: React.FC<LayoutProps> = (props: LayoutProps) => {
    const { isAdmin } = useContext(AuthContext);

    const { pathname } = useLocation();
    const history = useHistory();

    const [currentPage, setCurrentPage] = useState<string>();

    useEffect(() => {
        // to set the correct route after a refresh
        setCurrentPage(pathname.substring(1));

        const navigation = document.getElementById('navigation');
        const emptySpace = document.getElementById('emptySpace');
        if (navigation && emptySpace) {
            emptySpace.style.flex = `0 0 ${navigation.offsetHeight}px`;
        }
    }, [pathname]);

    const toggleMenu = (): void => {
        window.scrollTo(0, 0);
        const menu = document.getElementById('menu');
        if (menu) {
            menu.classList.toggle('hidden');
        }
    };

    const logout = async (event: MouseEvent<HTMLButtonElement>): Promise<void> => {
        event.preventDefault();

        await firebase.auth().signOut();
        TokenManager.deleteToken();
        history.push('/auth/login');
    };

    return (
        <>
            <nav id="navigation" className="fixed w-full z-10 bg-white">
                <div className="flex flex-col lg:flex-row">
                    <div className="flex flex-1">
                        <Link to="/" className="flex-1 flex">
                            <div>
                                <img src={logo} className="h-24 md:h-28" alt="Portale Consumatori" title="Portale Consumatori" />
                            </div>
                            <div className="flex-1 p-4 md:text-2xl uppercase flex items-center">
                                Il portale del <br /> consumatore
                            </div>
                        </Link>
                        <div className="lg:hidden flex items-center p-4">
                            <button className="text-pcons-green" onClick={(): void => toggleMenu()}>
                                <Hamburger className="fill-current" />
                            </button>
                        </div>
                    </div>

                    <div id="menu" className="hidden lg:flex flex-col lg:flex-row uppercase text-xl">
                        {isAdmin ? (
                            <>
                                <Link
                                    to="/categories"
                                    className={`p-4 h-full nav-item ${currentPage === 'categories' ? 'active' : ''}`}
                                    onClick={(): void => toggleMenu()}
                                >
                                    Classi merceologiche
                                </Link>
                                <Link
                                    to="/companies"
                                    className={`p-4 h-full nav-item ${currentPage === 'companies' ? 'active' : ''}`}
                                    onClick={(): void => toggleMenu()}
                                >
                                    Aziende
                                </Link>
                                <Link
                                    to="/export"
                                    className={`p-4 h-full nav-item ${currentPage === 'export' ? 'active' : ''}`}
                                    onClick={(): void => toggleMenu()}
                                >
                                    Esporta
                                </Link>
                            </>
                        ) : (
                            <>
                                <Link
                                    to="/user/companies"
                                    className={`p-4 h-full nav-item ${currentPage === 'user/companies' ? 'active' : ''}`}
                                    onClick={(): void => toggleMenu()}
                                >
                                    Pannello di controllo
                                </Link>
                                <Link
                                    to="/user/services"
                                    className={`p-4 h-full nav-item ${currentPage === 'user/services' ? 'active' : ''}`}
                                    onClick={(): void => toggleMenu()}
                                >
                                    Servizi dedicati
                                </Link>
                            </>
                        )}
                        <div>
                            <button onClick={logout} className="p-4 h-full nav-item">
                                LOGOUT
                            </button>
                        </div>
                    </div>
                </div>
                <div id="line" className="h-2 bg-pcons-green">
                    &nbsp;
                </div>
            </nav>

            <main className="flex flex-col h-full">
                <div id="emptySpace">&nbsp;</div>

                <div className={`flex-1 ${props.bodyClasses}`}>{props.children}</div>

                <footer className="p-4 text-center bg-gray-200 text-sm">
                    &copy; 2020 Il Portale del consumatore -&nbsp;
                    <a href="https://www.ilportaledelconsumatore.it/privacy" target="_blank" rel="noreferrer noopener">
                        Privacy Policy
                    </a>
                </footer>
            </main>
        </>
    );
};
