/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { toast } from 'react-toastify';

export class Notification {
    public static success(message: string): void {
        toast.success(message);
    }

    public static error(e: any): void {
        if (typeof e === 'string') {
            toast.error(e);
        }

        if (e.code) {
            toast.error(this.translate(e.code));
        }

        if (e.graphQLErrors && e.graphQLErrors.length > 0) {
            const errors = e.graphQLErrors.map((err: any) => ({ code: (err.extensions && err.extensions.code) || err.message }));
            errors.forEach((err: any) => toast.error(this.translate(err.code)));
        }
    }

    private static translate(code: string) {
        switch (code) {
            case 'auth/expired-action-code':
                return 'Il codice inviato è scaduto. Ricomincia la procedura';
            case 'auth/invalid-action-code':
                return 'Il codice di registrazione è errato, è scaduto o è già stato usato';
            case 'auth/user-disabled':
                return "L'utente è stato disabilitato dall'amministazione. Contattaci per maggiori informazioni";
            case 'auth/user-not-found':
                return 'Un utente con questo indirizzo email non esiste';
            case 'auth/weak-password':
                return 'la password non rispetta gli standard di sicurezza';
            case 'auth/invalid-email':
                return "L'indirizzo email specificato non è valido";
            case 'auth/wrong-password':
                return 'Password errata o utente disabilitato';
            case 'auth/email-already-in-use':
                return 'Un utente con questo indirizzo email esiste già';
            case 'UNEXPECTED':
                return 'Si è verificato un errore. Si prega di riprovare più tardi';
            case 'JWT_AUTH_UNABLE':
                return 'Sessione scaduta';
            case 'SLUG_TOO_MANY_RESULTS':
                return 'Troppi risultati con questo parametro';
            case 'CATEGORY_ID_NOT_FOUND':
                return 'Nessuna categoria con questo ID';
            case 'CATEGORY_SLUG_NOT_FOUND':
                return 'Nessuna categoria con questo nome';
            case 'CATEGORY_HAS_COMPANIES':
                return 'Questa categoria ha aziende attive';
            case 'COMPANY_ID_NOT_FOUND':
                return 'Nessuna azienda con questo ID';
            case 'COMPANY_HAS_OFFERS':
                return "L'azienda ha offerte attive";
            case 'COMPANY_SLUG_NOT_FOUND':
                return 'Nessuna azienda con questo nome';
            case 'DRAFT_NOT_FOUND':
                return 'Non ho trovato nessuna bozza per questa azienda';
            case 'OFFER_ID_NOT_FOUND':
                return 'Nessuna offerta con questo ID';
            case 'OFFER_ID_NOT_OWNED':
                return "L'offerta non è gestibile da altri se non il proprietario";
            default:
                return code;
        }
    }
}
