import { useState } from 'react';
import { Link } from 'react-router-dom';

import { Layout } from '../../components/_layout';

import n4 from '../../assets/n4.jpg';
import { DashboardItem } from '../../components/dashboard-item';

export const UserServices: React.FC = () => {
    const [showMarketing, setShowMarketing] = useState(false);
    const [showLogo, setShowLogo] = useState(false);
    const [showVetrina, setShowVetrina] = useState(false);
    const [showAnalisi, setShowAnalisi] = useState(false);
    const [showFacebookPage, setShowFacebookPage] = useState(false);
    const [showFacebookPost, setShowFacebookPost] = useState(false);

    return (
        <Layout>
            <div className="h-full bg-cover bg-center bg-no-repeat flex flex-col md:flex-row" style={{ backgroundImage: `url(${n4})` }}>
                <div className="md:w-1/2 lg:w-2/3">
                    <div className="md:m-4 xl:m-16 p-2 md:p-4" style={{ backgroundColor: 'rgba(255,255,255,0.5)' }}>
                        <div className="p-3 md:p-6" style={{ backgroundColor: 'rgba(255,255,255,0.8)' }}>
                            <h2 className="uppercase font-bold">Servizi dedicati</h2>
                            <p className="mt-4 md:text-xl uppercase">
                                Il portale del consumatore ti riserva vantaggiosi servizi professionale per dare ancora più valore alla tua impresa e far
                                crescere il tuo business.
                            </p>

                            <h4 className="font-bold uppercase text-pcons-green mt-8">Clicca quello che fa per te</h4>
                        </div>
                    </div>
                </div>

                <div className="md:w-1/2 lg:w-1/3 bg-white p-6 flex flex-col">
                    <button className="button text-white hover:text-white py-3" style={{ backgroundColor: '#5ccb55' }} onClick={() => setShowMarketing(true)}>
                        Consulenza marketing
                    </button>

                    <button className="mt-8 button text-white hover:text-white py-3" style={{ backgroundColor: '#00a295' }} onClick={() => setShowLogo(true)}>
                        Logo
                    </button>

                    <button
                        className="mt-8 button text-white hover:text-white py-3"
                        style={{ backgroundColor: '#00dbff' }}
                        onClick={() => setShowVetrina(true)}
                    >
                        Sito: vetrina
                    </button>

                    <button
                        className="mt-8 button text-white hover:text-white py-3"
                        style={{ backgroundColor: '#006dd7' }}
                        onClick={() => setShowAnalisi(true)}
                    >
                        Sito: analisi
                    </button>

                    <button
                        className="mt-8 button text-white hover:text-white py-3"
                        style={{ backgroundColor: '#feca00' }}
                        onClick={() => setShowFacebookPage(true)}
                    >
                        Facebook: pagina
                    </button>

                    <button
                        className="mt-8 button text-white hover:text-white py-3"
                        style={{ backgroundColor: '#f29a2b' }}
                        onClick={() => setShowFacebookPost(true)}
                    >
                        Facebook: post
                    </button>
                </div>
            </div>

            {showMarketing && (
                <DashboardItem
                    color="#5ccb55"
                    onClose={() => setShowMarketing(false)}
                    left={
                        <div className="text-white break-words flex flex-col">
                            <div className="text-2xl md:text-3xl uppercase text-center">Prenota la consulenza</div>
                            <div className="mt-16 text-2xl text-right">Clicca qui:</div>
                        </div>
                    }
                    right={
                        <div className="text-center text-xl lg:text-2xl h-full flex flex-col justify-center">
                            <b className="uppercase">40 minuti</b>
                            <div className="flex flex-col">
                                <span>di consulenza telefonica sugli argomenti che vuoi tu:</span>
                                <span>costruzione immagine, reputazione brand, relazione</span>
                                <span>col cliente, piano di comunicazione.</span>
                                <Link to={`/contact/marketing`} className="text-pcons-green mt-12">
                                    info@ilportaledelconsumatore.it
                                </Link>
                            </div>
                        </div>
                    }
                />
            )}

            {showLogo && (
                <DashboardItem
                    color="#00a295"
                    onClose={() => setShowLogo(false)}
                    left={
                        <div className="text-white break-words flex flex-col">
                            <div className="text-2xl md:text-3xl uppercase text-center">Manda la richiesta di contatto</div>
                            <div className="mt-16 text-2xl text-right">Clicca qui:</div>
                        </div>
                    }
                    right={
                        <div className="text-center text-xl lg:text-2xl h-full flex flex-col justify-center">
                            <b className="uppercase">Il tuo logo comunica</b>
                            <div className="flex flex-col">
                                <span>Il logo è l&apos;elemento che ti distingue perchè riassume</span>
                                <span>le caratteristiche e i valori della tua azienda.</span>
                                <span>Crealo o rinnovalo con un team qualificato!</span>
                                <Link to={`/contact/logo`} className="mt-16" style={{ color: '#00a295' }}>
                                    info@ilportaledelconsumatore.it
                                </Link>
                            </div>
                        </div>
                    }
                />
            )}

            {showVetrina && (
                <DashboardItem
                    color="#00dbff"
                    onClose={() => setShowVetrina(false)}
                    left={
                        <div className="text-white break-words flex flex-col">
                            <div className="text-2xl md:text-3xl uppercase text-center">Richiedi maggiori info</div>
                            <div className="mt-16 text-2xl text-right">Clicca qui:</div>
                        </div>
                    }
                    right={
                        <div className="text-center text-xl lg:text-2xl h-full flex flex-col justify-center">
                            <b className="uppercase">Porta l&apos;impresa in rete</b>
                            <div className="flex flex-col">
                                <span>Oggi è la regola:</span>
                                <span>prima di venire da te il cliente vuole conoscerti sul web.</span>
                                <span>E allora mettiti in evidenza con un sito professionale!</span>

                                <Link to={`/contact/sito-vetrina`} className="mt-12" style={{ color: '#00dbff' }}>
                                    info@ilportaledelconsumatore.it
                                </Link>
                            </div>
                        </div>
                    }
                />
            )}

            {showAnalisi && (
                <DashboardItem
                    color="#006dd7"
                    onClose={() => setShowAnalisi(false)}
                    left={
                        <div className="text-white break-words flex flex-col">
                            <div className="text-2xl md:text-3xl uppercase text-center">Richiedi l&apos;analisi e il report completo</div>
                            <div className="mt-16 text-2xl text-right">Clicca qui:</div>
                        </div>
                    }
                    right={
                        <div className="text-center text-xl lg:text-2xl h-full flex flex-col justify-center">
                            <b className="uppercase">Ops! il tuo sito non decolla</b>
                            <div className="flex flex-col">
                                <span>Verifica subito le possibili cause attraverso il</span>
                                <span>checkup digitale e metti in campo i suggerimenti</span>
                                <span>dell&apos;esperto per migliorarlo</span>
                                <Link to={`/contact/sito-analisi`} className="mt-24" style={{ color: '#006dd7' }}>
                                    info@ilportaledelconsumatore.it
                                </Link>
                            </div>
                        </div>
                    }
                />
            )}

            {showFacebookPage && (
                <DashboardItem
                    color="#feca00"
                    onClose={() => setShowFacebookPage(false)}
                    left={
                        <div className="text-white break-words flex flex-col">
                            <div className="text-2xl md:text-3xl uppercase text-center">Invia la tua richiesta di preventivo</div>
                            <div className="mt-16 text-2xl text-right">Clicca qui:</div>
                        </div>
                    }
                    right={
                        <div className="text-center text-xl lg:text-2xl h-full flex flex-col justify-center">
                            <b className="uppercase">Facebook: fattore di business</b>
                            <div className="flex flex-col">
                                <span>Qunato ti costa non essere conosciuto dal pubblico</span>
                                <span>dei social media? Sicuramente più della gestione</span>
                                <span>professionale della tua presenza in rete!</span>
                                <span>Provare per credere.</span>
                                <Link to={`/contact/pagina-facebook`} className="mt-12" style={{ color: '#feca00' }}>
                                    info@ilportaledelconsumatore.it
                                </Link>
                            </div>
                        </div>
                    }
                />
            )}

            {showFacebookPost && (
                <DashboardItem
                    color="#f29a2b"
                    onClose={() => setShowFacebookPost(false)}
                    left={
                        <div className="text-white break-words flex flex-col">
                            <div className="text-2xl md:text-3xl uppercase text-center">Per saperne di più</div>
                            <div className="mt-16 text-2xl text-right">Clicca qui:</div>
                        </div>
                    }
                    right={
                        <div className="text-center text-xl lg:text-2xl h-full flex flex-col justify-center px-16">
                            <b className="uppercase">Idea furba</b>
                            <div className="flex flex-col">
                                <span>Un mini catalogo di post per le tue</span>
                                <span>pagine social, &quot;pronti all&apos;uso&quot;.</span>
                                <span>Li vedi, li scegli, li pubblichi.</span>
                                <span>Più facile di così!</span>
                                <Link to={`/contact/pagina-facebook`} className="mt-4" style={{ color: '#f29a2b' }}>
                                    info@ilportaledelconsumatore.it
                                </Link>
                            </div>
                        </div>
                    }
                />
            )}
        </Layout>
    );
};
