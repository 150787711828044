import { ReactElement } from 'react';
import { RouteProps } from 'react-router-dom';

import { RegisterConfirmation } from './manage-components/register-confirmation';
import { ResetPassword } from './manage-components/reset-password';

export const Manage: React.FC = (props: RouteProps) => {
    const query = new URLSearchParams(props.location?.search);

    const what = (): ReactElement => {
        const code = query.get('oobCode');
        if (code) {
            switch (query.get('mode')) {
                case 'verifyEmail':
                    return <RegisterConfirmation code={code} />;
                case 'resetPassword':
                    return <ResetPassword code={code} />;
                default:
                    return <></>;
            }
        }

        return <></>;
    };

    return <div className="h-full p-4 xl:p-8 flex flex-col items-center justify-center">{what()}</div>;
};
