import { useContext, useEffect, useMemo, useState } from 'react';
import { useQuery, useMutation } from 'urql';
import { Link } from 'react-router-dom';

import { LoadingContext } from '../../contexts/LoadingProvider';

import { Notification } from '../../notification';

import { Layout } from '../../components/_layout';
import { ReactComponent as Sort } from '../../assets/sort.svg';
import { ReactComponent as Edit } from '../../assets/edit.svg';
import { ReactComponent as TrashCan } from '../../assets/trash-can.svg';

const GETCATEGORIES = `
    query($limit: Int, $cursor: Int, $orderBy: CategoryOrderByInput) {
        categories(limit: $limit, cursor: $cursor, orderBy: $orderBy) {
            edges {
                id
                name
                order
            }
            pageInfo {
                hasNextPage
                endCursor
            }
        }
    }
`;

export const Categories: React.FC = () => {
    const { setIsLoading } = useContext(LoadingContext);
    const [cursor, setCursor] = useState(0);
    const [orderBy, setOrderBy] = useState('name_ASC');

    const limit = 25;

    const variables = useMemo(
        () => ({
            limit,
            cursor,
            orderBy
        }),
        [cursor, orderBy]
    );

    const [{ data, fetching, error }, reexecuteQuery] = useQuery({ query: GETCATEGORIES, variables, requestPolicy: 'cache-and-network' });

    const sort = (cur: number, order?: string): void => {
        setCursor(cur);
        setOrderBy(order || orderBy);
    };

    const [, deleteCategory] = useMutation(
        `mutation($id: ID!) {
            deleteCategory(id: $id)
        }`
    );

    const del = async (id: string): Promise<void> => {
        if (window.confirm('Sei sicuro di voler eliminare questa classe merceologica?')) {
            const { data, error } = await deleteCategory({ id });

            if (data.deleteCategory) {
                reexecuteQuery();

                Notification.success('Classe eliminata con successo');
            }

            if (error) {
                Notification.error(error);
            }
        }
    };

    useEffect(() => {
        if (setIsLoading) {
            setIsLoading(fetching);
        }
    }, [setIsLoading, fetching]);

    if (error) {
        Notification.error(error);
        return <></>;
    }

    return (
        <Layout bodyClasses="p-4">
            <h3 className="flex items-center">
                Classi merceologiche
                <Link to="/categories/manage" className="ml-4 rounded-full h-8 w-8 flex items-center justify-center bg-pcons-green text-white">
                    +
                </Link>
            </h3>
            <div className="overflow-x-auto md:overflow-hidden p-1 mt-8">
                <table className="table-fixed min-w-full">
                    <thead>
                        <tr>
                            <td className="px-4 py-2 text-gray-600">
                                <button
                                    className="flex items-center font-bold"
                                    onClick={(): void => sort(cursor, orderBy === 'name_ASC' ? 'name_DESC' : 'name_ASC')}
                                >
                                    Nome <Sort className="fill-current ml-2 h-4" />
                                </button>
                            </td>
                            <td className="w-32 px-4 py-2 text-gray-600">
                                <button
                                    className="flex items-center font-bold"
                                    onClick={(): void => sort(cursor, orderBy === 'order_ASC' ? 'order_DESC' : 'order_ASC')}
                                >
                                    Ord. <Sort className="fill-current ml-2 h-4" />
                                </button>
                            </td>
                            <td style={{ width: '140px' }}></td>
                        </tr>
                    </thead>
                    <tbody>
                        {data && data.categories && data.categories.edges.length > 0 ? (
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            data.categories.edges.map((cat: any) => (
                                <tr key={cat.id}>
                                    <td className="border-b px-4 py-2">{cat.name}</td>
                                    <td className="border-b px-4 py-2">{cat.order}</td>
                                    <td className="border-b px-4 py-2">
                                        <div className="flex items-center">
                                            <Link to={`/categories/manage/${cat.id}`} className="button green">
                                                <Edit className="fill-current text-white" title="Modifica" />
                                            </Link>
                                            <button className="button red ml-2" onClick={(): Promise<void> => del(cat.id)}>
                                                <TrashCan className="fill-current text-white" title="Elimina" />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td className="border px-4 py-2" colSpan={4}>
                                    Nessuna classe merceologica inserita
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>

                <div className="mt-8 flex justify-center">
                    <div className="border border-gray-700 rounded flex">
                        {cursor && cursor > 0 ? (
                            <button className="block w-32 p-4 text-center font-semibold" onClick={(): void => sort(cursor - limit)}>
                                INDIETRO
                            </button>
                        ) : (
                            <></>
                        )}
                        {data && data.categories.pageInfo.hasNextPage && (
                            <button
                                className="even:border-l border-gray-700 block w-32 p-4 text-center font-semibold"
                                onClick={(): void => sort(data.categories.pageInfo.endCursor)}
                            >
                                AVANTI
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </Layout>
    );
};
