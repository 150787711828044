import { useContext, useState, useEffect, ChangeEvent } from 'react';
import { useQuery, useMutation } from 'urql';
import { useForm } from 'react-hook-form';
import { useParams, useHistory, Link } from 'react-router-dom';
import ReactQuill from 'react-quill';

import { LoadingContext } from '../../contexts/LoadingProvider';

import { Notification } from '../../notification';

import { Layout } from '../../components/_layout';

import { ReactComponent as Chevron } from '../../assets/left-chevron.svg';

import 'react-quill/dist/quill.snow.css';

const GETCOMPANY = `query($companyId: ID!, $id: ID!) {
    offerOwnedById(companyId: $companyId, id: $id) {
        title
        slug
        description
        image {
            url
        }
        company {
            slug
        }
    }
}`;

export const ManageUserOffer: React.FC = () => {
    const { setIsLoading } = useContext(LoadingContext);
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useForm();
    const history = useHistory();

    const { companyId } = useParams<{ companyId: string }>();
    let { id } = useParams<{ id: string }>();

    const [{ data, fetching, error }] = useQuery({ query: GETCOMPANY, variables: { companyId, id }, pause: !id });

    const [description, setDescription] = useState<string>();

    const [slug, setSlug] = useState<string>();
    const [image, setImage] = useState<string>();

    useEffect(() => {
        if (setIsLoading) {
            setIsLoading(fetching);
        }
    }, [setIsLoading, fetching]);

    useEffect(() => {
        if (data && data.offerOwnedById) {
            setValue('title', data.offerOwnedById.title);
            setSlug(`${data.offerOwnedById.company.slug}/${data.offerOwnedById.slug}`);
            setTimeout(() => setDescription(data.offerOwnedById.description), 50);
            if (data.offerOwnedById.image) {
                setImage(data.offerOwnedById.image.url);
            }
        }
    }, [data, setValue]);

    const [, manageOfferByOwner] = useMutation(
        `mutation($companyId: ID!, $id: ID, $input: OfferInput!) {
            manageOfferByOwner(companyId: $companyId, id: $id, input: $input)
        }`
    );

    const onSubmit = async (form: Record<string, string | number>): Promise<void> => {
        if (description) {
            form.description = description;
        }

        const { data, error } = await manageOfferByOwner({ companyId, id, input: form });

        if (data.manageOfferByOwner) {
            if (!id) {
                id = data.manageOfferByOwner;
                history.replace(`/user/offers/manage/${companyId}/${id}`);
            }

            Notification.success('Offerta salvata con successo');
        }

        if (error) {
            Notification.error(error);
        }
    };

    const [, addOfferImageByOwner] = useMutation(
        `mutation($companyId: ID!, $id: ID!, $file: Upload!) {
            addOfferImageByOwner(companyId: $companyId, id: $id, file: $file)
        }`
    );

    const uploadImage = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
        event.preventDefault();

        const file = event.target.files && event.target.files[0];

        const { data, error } = await addOfferImageByOwner({ companyId, id, file });

        if (data.addOfferImageByOwner) {
            setImage(data.addOfferImageByOwner);
        }

        if (error) {
            Notification.error(error);
        }
    };

    const [, removeOfferImageByOwner] = useMutation(
        `mutation($companyId: ID!, $id: ID!) {
            removeOfferImageByOwner(companyId: $companyId, id: $id)
        }`
    );

    const removeImage = async (): Promise<void> => {
        const { data, error } = await removeOfferImageByOwner({ companyId, id });

        if (data.removeOfferImageByOwner) {
            setImage(undefined);
        }

        if (error) {
            Notification.error(error);
        }
    };

    if (error) {
        Notification.error(error);
        return <></>;
    }

    return (
        <Layout bodyClasses="p-4">
            <div className="flex items-center">
                <Link to={`/user/offers/${companyId}`} className="text-pcons-green text-3xl">
                    <Chevron className="fill-current h-8 w-8" />
                </Link>
                <h3 className="ml-4">Crea la tua offerta e carica il logo</h3>
            </div>

            <div className="w-full xl:w-1/2 mx-auto flex flex-col md:flex-row">
                <form noValidate className="flex-1 p-4" onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-6">
                        <label className="label" htmlFor="title">
                            Titolo offerta
                        </label>
                        <input
                            type="text"
                            {...register('title', { required: "Il titolo dell'offerta è obbligatorio" })}
                            className="input"
                            placeholder="Titolo offerta"
                            aria-label="Titolo offerta"
                        />
                        <p className="mt-2 text-red-500 text-xs italic">{errors.name && errors.name.message}</p>
                    </div>

                    <div className="mb-6">
                        <label className="label" htmlFor="description">
                            Descrizione
                        </label>
                        <ReactQuill theme="snow" value={description} onChange={setDescription}>
                            <div className="bg-gray-200 text-gray-700" style={{ fontFamily: 'Montserrat, sans-serif', fontSize: '1.25rem' }} />
                        </ReactQuill>
                    </div>

                    <button type="submit" className="button green w-full">
                        Salva
                    </button>

                    {slug && (
                        <div className="mt-4">
                            <button
                                type="button"
                                className="button w-full text-white"
                                style={{ backgroundColor: '#4267B2' }}
                                onClick={() => {
                                    window.open(
                                        `https://www.facebook.com/sharer/sharer.php?u=https://www.ilportaledelconsumatore.it/details/${slug}`,
                                        'facebook-share-dialog',
                                        'width=626,height=436'
                                    );
                                    return false;
                                }}
                            >
                                Condividi questa pagina su Facebook
                            </button>
                        </div>
                    )}
                </form>

                {id && (
                    <div className="w-full md:w-1/3 lg:w-2/6 p-4">
                        <span className="label">Immagine</span>
                        {image ? (
                            <>
                                <div className="flex justify-center items-center">
                                    <img src={image} alt="Immagine offerta" title="Immagine offerta" />
                                </div>
                                <button type="button" className="button red mt-4 w-full" onClick={removeImage}>
                                    Elimina
                                </button>
                            </>
                        ) : (
                            <>
                                <input type="file" id="file" className="hidden" accept=".jpg,.png,.jpeg" onChange={uploadImage} />
                                <label
                                    htmlFor="file"
                                    className="mt-2 w-full h-64 border-4 border-dashed border-pcons-green rounded-lg flex items-center justify-center text-6xl text-pcons-green
                    cursor-pointer"
                                >
                                    +
                                </label>
                            </>
                        )}
                    </div>
                )}
            </div>
        </Layout>
    );
};
