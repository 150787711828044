/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useEffect, useMemo, useState } from 'react';
import { useQuery, useMutation } from 'urql';
import { useParams, Link } from 'react-router-dom';
import dayjs from 'dayjs';

import { LoadingContext } from '../../contexts/LoadingProvider';

import { Notification } from '../../notification';

import { Layout } from '../../components/_layout';
import { ReactComponent as Sort } from '../../assets/sort.svg';
import { ReactComponent as Eyeball } from '../../assets/eyeball.svg';
import { ReactComponent as TrashCan } from '../../assets/trash-can.svg';
import { ReactComponent as Chevron } from '../../assets/left-chevron.svg';
import { ReactComponent as Close } from '../../assets/close.svg';

const GETOFFERS = `
    query($companyId: ID!, $limit: Int, $cursor: Int, $orderBy: OfferOrderByInput) {
        offers(companyId: $companyId, limit: $limit, cursor: $cursor, orderBy: $orderBy) {
            edges {
                id
                title
                description
                date
                image {
                    url
                }
            }
            pageInfo {
                hasNextPage
                endCursor
            }
        }
    }
`;

export const Offers: React.FC = () => {
    const { id } = useParams<{ id: string }>();

    const { setIsLoading } = useContext(LoadingContext);
    const [cursor, setCursor] = useState(0);
    const [orderBy, setOrderBy] = useState('date_DESC');

    const [offerView, setOfferView] = useState<Record<string, any>>();

    const limit = 25;

    const variables = useMemo(
        () => ({
            companyId: id,
            limit,
            cursor,
            orderBy
        }),
        [id, cursor, orderBy]
    );

    const [{ data, fetching, error }, reexecuteQuery] = useQuery({ query: GETOFFERS, variables, requestPolicy: 'cache-and-network' });

    const sort = (cur: number, order?: string): void => {
        setCursor(cur);
        setOrderBy(order || orderBy);
    };

    const [, deleteOffer] = useMutation(
        `mutation($id: ID!) {
            deleteOffer(id: $id)
        }`
    );

    const del = async (id: string): Promise<void> => {
        if (window.confirm("Sei sicuro di voler eliminare quest'offerta?")) {
            const { data, error } = await deleteOffer({ id });

            if (data.deleteOffer) {
                reexecuteQuery();

                Notification.success('Azienda eliminata con successo');
            }

            if (error) {
                Notification.error(error);
            }
        }
    };

    const openOffer = (offer: Record<string, any>) => {
        setOfferView(offer);

        const modal = document.getElementById('offer-viewer');
        if (modal) {
            modal.classList.replace('hidden', 'flex');
        }
    };

    const closeOffer = () => {
        setOfferView(undefined);

        const modal = document.getElementById('offer-viewer');
        if (modal) {
            modal.classList.replace('flex', 'hidden');
        }
    };

    useEffect(() => {
        if (setIsLoading) {
            setIsLoading(fetching);
        }
    }, [setIsLoading, fetching]);

    if (error) {
        Notification.error(error);
        return <></>;
    }

    return (
        <Layout bodyClasses="p-4">
            <div className="flex items-center">
                <Link to="/companies" className="text-pcons-green text-3xl">
                    <Chevron className="fill-current h-8 w-8" />
                </Link>
                <h3 className="ml-4">Offerte</h3>
            </div>
            <div className="overflow-x-auto md:overflow-hidden p-1 mt-8">
                <table className="table-fixed min-w-full">
                    <thead>
                        <tr>
                            <td className="px-4 py-2 font-bold text-gray-600">Titolo</td>
                            <td className="px-4 py-2 text-gray-600" style={{ width: '230px' }}>
                                <button
                                    className="flex items-center font-bold"
                                    onClick={(): void => sort(cursor, orderBy === 'date_ASC' ? 'date_DESC' : 'date_ASC')}
                                >
                                    Del <Sort className="fill-current ml-2 h-4" />
                                </button>
                            </td>
                            <td style={{ width: '140px' }} />
                        </tr>
                    </thead>
                    <tbody>
                        {data && data.offers && data.offers.edges.length > 0 ? (
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            data.offers.edges.map((offer: any) => (
                                <tr key={offer.id}>
                                    <td className="border-b px-4 py-2">{offer.title}</td>
                                    <td className="border-b px-4 py-2">{dayjs(offer.date).format('DD MMMM YYYY HH:mm')}</td>
                                    <td className="border-b px-4 py-2">
                                        <div className="flex items-center">
                                            <button className="button green" onClick={(): void => openOffer(offer)}>
                                                <Eyeball className="fill-current text-white" title="Modifica" />
                                            </button>
                                            <button className="button red ml-2" onClick={(): Promise<void> => del(offer.id)}>
                                                <TrashCan className="fill-current text-white" title="Elimina" />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td className="px-4 py-2" colSpan={6}>
                                    Nessuna offerta inserita per questa azienda
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>

                <div className="mt-8 flex justify-center">
                    <div className="border border-gray-700 rounded flex">
                        {cursor && cursor > 0 ? (
                            <button className="block w-32 p-4 text-center font-semibold" onClick={(): void => sort(cursor - limit)}>
                                INDIETRO
                            </button>
                        ) : (
                            <></>
                        )}
                        {data && data.offers.pageInfo.hasNextPage && (
                            <button
                                className="even:border-l border-gray-700 block w-32 p-4 text-center font-semibold"
                                onClick={(): void => sort(data.offers.pageInfo.endCursor)}
                            >
                                AVANTI
                            </button>
                        )}
                    </div>
                </div>
            </div>

            <div id="offer-viewer" className="fixed w-full h-full top-0 left-0 hidden items-center justify-center">
                <div className="absolute w-full h-full bg-gray-900 opacity-50"></div>

                <div className="bg-white max-h-screen w-full md:w-4/5 lg:w-1/2 p-4 rounded shadow-lg z-20 overflow-y-auto">
                    <div className="flex justify-between items-center pb-3">
                        <p className="text-3xl">Dettaglio offerta</p>
                        <button className="cursor-pointer z-20" onClick={(): void => closeOffer()}>
                            <Close className="fill-current text-black" />
                        </button>
                    </div>
                    <h5>Titolo: {offerView?.title} </h5>
                    <h5 className="mt-2">Descrizione</h5>
                    <div className="mt-4" dangerouslySetInnerHTML={{ __html: `${offerView?.description}` }} />
                    {offerView?.image && <img className="mt-4" style={{ maxHeight: '400px' }} src={offerView?.image.url} alt="Immagine offerta" />}
                </div>
            </div>
        </Layout>
    );
};
