import { useContext, useEffect, useState } from 'react';
import { useMutation } from 'urql';
import { Link, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { LoadingContext } from '../../contexts/LoadingProvider';

import { Notification } from '../../notification';

import firebase from '../../firebase';

import { Layout } from '../../components/_layout';

import { ReactComponent as Chevron } from '../../assets/left-chevron.svg';

type Form = {
    requestBody: string;
};

export const Contact: React.FC = () => {
    const { setIsLoading } = useContext(LoadingContext);
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<Form>();

    const { kind } = useParams<{ kind: string }>();

    const [email, setEmail] = useState<string>();

    useEffect(() => {
        if (firebase.auth().currentUser) {
            setEmail(firebase.auth().currentUser?.email as string);
        }
    }, []);

    const [, sendContact] = useMutation(
        `mutation($email: String!, $kind:String!, $body: String!) {
            sendContact(email: $email, kind: $kind, body: $body)
        }`
    );

    const onSubmit = async (form: Form): Promise<void> => {
        if (setIsLoading && email && form.requestBody.trim()) {
            setIsLoading(true);

            const { error } = await sendContact({ email, kind, body: form.requestBody });

            setIsLoading(false);

            if (error) {
                Notification.error(error);
            } else {
                Notification.success('Abbiamo ricevuto la tua richiesta. Grazie per il contatto!');
            }
        }
    };

    return (
        <Layout bodyClasses="p-4">
            <div className="flex items-center">
                <Link to="/" className="text-pcons-green text-3xl">
                    <Chevron className="fill-current h-8 w-8" />
                </Link>
                <h3 className="ml-4">Torna alla home</h3>
            </div>

            <div className="mt-8 w-full xl:w-1/2 mx-auto">
                <h2>Contattaci</h2>

                <form noValidate className="flex-1" onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-6">
                        <label className="label" htmlFor="requestBody">
                            La tua richiesta
                        </label>
                        <textarea
                            {...register('requestBody', { required: true })}
                            className="input"
                            placeholder="La tua richiesta"
                            aria-label="Titolo offerta"
                        />
                        {errors.requestBody && <p className="mt-2 text-red-500 text-xs italic">Il corpo della richiesta è obbligatorio</p>}
                    </div>

                    <button type="submit" className="button green w-full">
                        Invia la tua richiesta
                    </button>
                </form>
            </div>
        </Layout>
    );
};
