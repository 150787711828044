import { useContext, useEffect, useMemo, useState } from 'react';
import { useQuery, useMutation } from 'urql';
import { Link, useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import { LoadingContext } from '../../contexts/LoadingProvider';

import { Notification } from '../../notification';

import { Layout } from '../../components/_layout';
import { ReactComponent as Sort } from '../../assets/sort.svg';
import { ReactComponent as Edit } from '../../assets/edit.svg';
import { ReactComponent as TrashCan } from '../../assets/trash-can.svg';
import { ReactComponent as Chevron } from '../../assets/left-chevron.svg';

const GETOFFERS = `
    query($companyId: ID!, $limit: Int, $cursor: Int, $orderBy: OfferOrderByInput) {
        offers(companyId: $companyId, limit: $limit, cursor: $cursor, orderBy: $orderBy) {
            edges {
                id
                title
                description
                date
            }
            pageInfo {
                hasNextPage
                endCursor
            }
        }
    }
`;

export const UserOffers: React.FC = () => {
    const { id } = useParams<{ id: string }>();

    const { setIsLoading } = useContext(LoadingContext);
    const [cursor, setCursor] = useState(0);
    const [orderBy, setOrderBy] = useState('date_DESC');

    const limit = 25;

    const variables = useMemo(
        () => ({
            companyId: id,
            limit,
            cursor,
            orderBy
        }),
        [id, cursor, orderBy]
    );

    const [{ data, fetching, error }, reexecuteQuery] = useQuery({ query: GETOFFERS, variables, requestPolicy: 'cache-and-network' });

    const sort = (cur: number, order?: string): void => {
        setCursor(cur);
        setOrderBy(order || orderBy);
    };

    const [, deleteOfferByOwner] = useMutation(
        `mutation($companyId: ID!, $id: ID!) {
            deleteOfferByOwner(companyId: $companyId, id: $id)
        }`
    );

    const del = async (offerId: string): Promise<void> => {
        if (window.confirm("Sei sicuro di voler eliminare quest'offerta?")) {
            const { data, error } = await deleteOfferByOwner({ companyId: id, id: offerId });

            if (data.deleteOfferByOwner) {
                reexecuteQuery();

                Notification.success('Offerta eliminata con successo');
            }

            if (error) {
                Notification.error(error);
            }
        }
    };

    useEffect(() => {
        if (setIsLoading) {
            setIsLoading(fetching);
        }
    }, [setIsLoading, fetching]);

    if (error) {
        Notification.error(error);
        return <></>;
    }

    return (
        <Layout bodyClasses="p-4">
            <div className="flex items-center">
                <Link to="/user/companies" className="text-pcons-green text-3xl">
                    <Chevron className="fill-current h-8 w-8" />
                </Link>
                <h3 className="ml-4">Offerte</h3>
            </div>
            <div className="overflow-x-auto md:overflow-hidden p-1 mt-8">
                <table className="table-fixed min-w-full">
                    <thead>
                        <tr>
                            <td className="px-4 py-2 font-bold text-gray-600">Titolo</td>
                            <td className="px-4 py-2 text-gray-600" style={{ width: '230px' }}>
                                <button
                                    className="flex items-center font-bold"
                                    onClick={(): void => sort(cursor, orderBy === 'date_ASC' ? 'date_DESC' : 'date_ASC')}
                                >
                                    Data <Sort className="fill-current ml-2 h-4" />
                                </button>
                            </td>
                            <td style={{ width: '140px' }} />
                        </tr>
                    </thead>
                    <tbody>
                        {data && data.offers && data.offers.edges.length > 0 ? (
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            data.offers.edges.map((offer: any) => (
                                <tr key={offer.id}>
                                    <td className="border-b px-4 py-2">{offer.title}</td>
                                    <td className="border-b px-4 py-2">{dayjs(offer.date).format('DD MMMM YYYY HH:mm')}</td>
                                    <td className="border-b px-4 py-2">
                                        <div className="flex items-center">
                                            <Link to={`/user/offers/manage/${id}/${offer.id}`} className="button green md:mt-0 md:ml-2">
                                                <Edit className="fill-current text-white" title="Modifica" />
                                            </Link>
                                            <button className="button red mt-2 md:mt-0 md:ml-2" onClick={(): Promise<void> => del(offer.id)}>
                                                <TrashCan className="fill-current text-white" title="Elimina" />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td className="px-4 py-2" colSpan={6}>
                                    Nessuna offerta inserita per questa azienda
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>

                <div className="mt-8 flex justify-center">
                    <div className="border border-gray-700 rounded flex">
                        {cursor && cursor > 0 ? (
                            <button className="block w-32 p-4 text-center font-semibold" onClick={(): void => sort(cursor - limit)}>
                                INDIETRO
                            </button>
                        ) : (
                            <></>
                        )}
                        {data && data.offers.pageInfo.hasNextPage && (
                            <button
                                className="even:border-l border-gray-700 block w-32 p-4 text-center font-semibold"
                                onClick={(): void => sort(data.offers.pageInfo.endCursor)}
                            >
                                AVANTI
                            </button>
                        )}
                    </div>
                </div>
            </div>

            <div className="mt-8 bg-gray-200 p-4">
                {data && data.offers.edges.length < 4 ? (
                    <h4>
                        {data && data.offers.edges.length > 0 ? 'Vuoi inserire una nuova offerta?' : 'Inserisci la tua prima offerta'}
                        <Link to={`/user/offers/manage/${id}`} className="button">
                            + Clicca qui
                        </Link>
                    </h4>
                ) : (
                    <b>Non puoi inserire più di 4 offerte. Se vuoi inserire una nuova offerta, eliminane una attualmente presente</b>
                )}
            </div>

            <div className="mt-8 bg-gray-100 p-4 text-sm">
                <b>Legenda delle icone nella pagina:</b>
                <div className="flex items-center mt-2">
                    <Edit className="ml-1 mr-1" /> permette di apportare modifiche mentre
                    <TrashCan className="ml-1 mr-1" /> elimina l&apos;offerta definitivamente
                </div>
            </div>
        </Layout>
    );
};
