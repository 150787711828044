import { useState, useEffect } from 'react';
import { RouteProps, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import firebase from '../../../firebase';

import { Notification } from '../../../notification';

export interface ResetPasswordProps extends RouteProps {
    code: string;
}

export const ResetPassword: React.FC<ResetPasswordProps> = (props: ResetPasswordProps) => {
    const {
        register,
        watch,
        handleSubmit,
        formState: { errors }
    } = useForm();
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
    const [email, setEmail] = useState<string>('');

    useEffect(() => {
        const verifyCode = async (): Promise<void> => {
            try {
                const result = await firebase.auth().verifyPasswordResetCode(props.code);
                setEmail(result);
            } catch (e) {
                Notification.error(e);
            }
        };

        verifyCode();
    }, [props.location, props.code]);

    const onSubmit = async (form: Record<string, string>): Promise<void> => {
        try {
            await firebase.auth().confirmPasswordReset(props.code, form.password);
            setShowConfirmation(true);
        } catch (e) {
            Notification.error(e);
        }
    };

    return (
        <>
            {email === '' ? (
                <></>
            ) : !showConfirmation ? (
                <>
                    <form noValidate onSubmit={handleSubmit(onSubmit)} className="w-full xl:w-1/4 md:w-4/6">
                        <h3>Completa il cambio della password</h3>
                        <p className="mb-4">Crea una nuova password per il tuo account {email}</p>
                        <div className="mb-6">
                            <label className="label" htmlFor="password">
                                Password
                            </label>
                            <input
                                type="password"
                                {...register('password', {
                                    required: 'La password è obbligatoria',
                                    pattern: {
                                        value: /^.*(?=.{6,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1})/,
                                        message: 'La password non rispetta gli standard di sicurezza'
                                    }
                                })}
                                className="input"
                                placeholder="Password"
                                aria-label="Password"
                            />
                            <p className="mt-2 text-red-500 text-xs italic">{errors.password && errors.password.message}</p>
                        </div>
                        <div className="mb-6">
                            <label className="label" htmlFor="confirmPassword">
                                Ripeti la password
                            </label>
                            <input
                                type="password"
                                {...register('confirmPassword', {
                                    validate: value => value === watch('password') || 'Le password non combaciano'
                                })}
                                className="input"
                                placeholder="Ripeti la password"
                                aria-label="Ripeti la password"
                            />
                            <p className="mt-2 text-red-500 text-xs italic">{errors.confirmPassword && errors.confirmPassword.message}</p>
                        </div>
                        <button type="submit" className="button green w-full">
                            Imposta la nuova password
                        </button>
                        <p className="mt-6 text-sm">
                            La password deve essere di almeno 6 caratteri e deve contenere una cifra, una lettera maiuscola e un carattere speciale (@, &, !, ?,
                            etc...)
                        </p>
                    </form>
                </>
            ) : (
                <div className="w-full md:w-1/3 text-center">
                    <h1>La tua password è stata cambiata</h1>
                    <p className="text-xl"> Ora puoi accedere con le tue nuovi credenziali.</p>
                    <Link to="/auth/login" className="block mt-8 button text-base">
                        Vai alla pagina di login
                    </Link>
                </div>
            )}
        </>
    );
};
