import { useContext, useEffect, useMemo, useState } from 'react';
import { useQuery, useMutation } from 'urql';
import { Link } from 'react-router-dom';

import { LoadingContext } from '../../contexts/LoadingProvider';

import { Notification } from '../../notification';

import { Layout } from '../../components/_layout';
import { Select } from '../../components/select';
import { ReactComponent as Sort } from '../../assets/sort.svg';
import { ReactComponent as EyeBall } from '../../assets/eyeball.svg';
import { ReactComponent as Edit } from '../../assets/edit.svg';
import { ReactComponent as Draft } from '../../assets/draft.svg';
import { ReactComponent as TrashCan } from '../../assets/trash-can.svg';
import { ReactComponent as Megaphone } from '../../assets/megaphone.svg';

const GETREGIONS = `query { searchRegions }`;
const GETPROVINCES = `query($region: String!) { 
    searchProvinces(region: $region) { 
        name code 
    } 
}`;

const GETCITIES = `query($provinceCode: String!) {
    searchCities(provinceCode: $provinceCode)
}`;

const GETCCOMPANIES = `
    query($city: String, $limit: Int, $cursor: Int, $orderBy: CompanyOrderByInput) {
        companyADList(city: $city, limit: $limit, cursor: $cursor, orderBy: $orderBy) {
            edges {
                id
                owner
                name
                cap
                region
                province
                city
                category {
                    name
                }
                visible
                draftId
            }
            pageInfo {
                hasNextPage
                endCursor
            }
        }
    }
`;

export const Companies: React.FC = () => {
    const { setIsLoading } = useContext(LoadingContext);

    const [region, setRegion] = useState<string>();
    const [provinceCode, setProvinceCode] = useState<string>();
    const [city, setCity] = useState<string>();

    const [regions] = useQuery({ query: GETREGIONS });
    const [provinces] = useQuery({ query: GETPROVINCES, variables: { region }, pause: !region });
    const [cities] = useQuery({ query: GETCITIES, variables: { provinceCode }, pause: !provinceCode });

    const [cursor, setCursor] = useState(0);
    const [orderBy, setOrderBy] = useState('name_ASC');

    const limit = 25;

    const variables = useMemo(
        () => ({
            city,
            limit,
            cursor,
            orderBy
        }),
        [city, cursor, orderBy]
    );

    const [companies, reexecuteQuery] = useQuery({ query: GETCCOMPANIES, variables, requestPolicy: 'cache-and-network' });

    const sort = (cur: number, order?: string): void => {
        setCursor(cur);
        setOrderBy(order || orderBy);
    };

    const [, deleteCompany] = useMutation(
        `mutation($id: ID!) {
            deleteCompany(id: $id)
        }`
    );

    const del = async (id: string): Promise<void> => {
        if (window.confirm("Sei sicuro di voler eliminare quest'azienda?")) {
            const { data, error } = await deleteCompany({ id });

            if (data.deleteCompany) {
                reexecuteQuery();

                Notification.success('Azienda eliminata con successo');
            }

            if (error) {
                Notification.error(error);
            }
        }
    };

    useEffect(() => {
        if (setIsLoading) {
            setIsLoading(companies.fetching);
        }
    }, [setIsLoading, companies.fetching]);

    if (companies.error) {
        Notification.error(companies.error);
        return <></>;
    }

    return (
        <Layout bodyClasses="p-4">
            <h3 className="flex items-center">
                Aziende
                <Link to="/companies/manage" className="ml-4 rounded-full h-8 w-8 flex items-center justify-center bg-pcons-green text-white">
                    +
                </Link>
            </h3>

            <div className="mt-4">
                <div className="text-right font-bold text-pcons-green">Filtra per città</div>
                <div className=" flex justify-end">
                    <div className="w-64">
                        <label className="label" htmlFor="region">Regione</label>
                        <Select name="region" aria-label="Regione" onChange={event => setRegion(event.target.value)}>
                            <option value="">...</option>
                            {regions.data?.searchRegions &&
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                regions.data?.searchRegions.map((r: string) => (
                                    <option key={r} value={r}>
                                        {r}
                                    </option>
                                ))}
                        </Select>
                    </div>

                    <div className="ml-2 w-64">
                        <label className="label" htmlFor="province">Provincia</label>
                        <Select name="province" aria-label="Provincia" onChange={event => setProvinceCode(event.target.value)}>
                            <option value="">...</option>
                            {provinces.data?.searchProvinces &&
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                provinces.data?.searchProvinces.map((p: any) => (
                                    <option key={p.code} value={p.code}>
                                        {p.name}
                                    </option>
                                ))}
                        </Select>
                    </div>

                    <div className="ml-2 w-64">
                        <label className="label" htmlFor="city">Città</label>
                        <Select name="city" aria-label="Città" onChange={event => setCity(event.target.value)}>
                            <option value="">...</option>
                            {cities.data?.searchCities &&
                                cities.data?.searchCities.map((c: string) => (
                                    <option key={c} value={c}>
                                        {c}
                                    </option>
                                ))}
                        </Select>
                    </div>
                </div>
            </div>

            <div className="overflow-x-auto md:overflow-hidden p-1 mt-8">
                <table className="table-fixed min-w-full">
                    <thead>
                        <tr>
                            <td className="w-12" />
                            <td className="px-4 py-2 text-gray-600">
                                <button
                                    className="flex items-center font-bold"
                                    onClick={(): void => sort(cursor, orderBy === 'name_ASC' ? 'name_DESC' : 'name_ASC')}
                                >
                                    Nome <Sort className="fill-current ml-2 h-4" />
                                </button>
                            </td>
                            <td className="w-32 px-4 py-2 font-bold text-gray-600">CAP</td>
                            <td className="w-12 px-4 py-2 text-gray-600">
                                <button
                                    className="flex items-center font-bold"
                                    onClick={(): void => sort(cursor, orderBy === 'region_ASC' ? 'region_DESC' : 'region_ASC')}
                                >
                                    Regione <Sort className="fill-current ml-2 h-4" />
                                </button>
                            </td>
                            <td className="w-12 px-4 py-2 text-gray-600">
                                <button
                                    className="flex items-center font-bold"
                                    onClick={(): void => sort(cursor, orderBy === 'province_ASC' ? 'province_DESC' : 'province_ASC')}
                                >
                                    Provincia <Sort className="fill-current ml-2 h-4" />
                                </button>
                            </td>
                            <td className="w-12 px-4 py-2 text-gray-600">
                                <button
                                    className="flex items-center font-bold"
                                    onClick={(): void => sort(cursor, orderBy === 'city_ASC' ? 'city_DESC' : 'city_ASC')}
                                >
                                    Città <Sort className="fill-current ml-2 h-4" />
                                </button>
                            </td>
                            <td className="w-64 px-4 py-2 font-bold text-gray-600">Categoria</td>
                            <td style={{ width: '240px' }} />
                        </tr>
                    </thead>
                    <tbody>
                        {companies.data && companies.data.companyADList && companies.data.companyADList.edges.length > 0 ? (
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            companies.data.companyADList.edges.map((company: any) => (
                                <tr key={company.id}>
                                    <td>
                                        <div className={`button py-2 ${company.visible ? 'green' : 'red'}`}>
                                            <EyeBall className="fill-current w-6 h-6" title="Visibile" />
                                        </div>
                                    </td>
                                    <td className="border-b px-4 py-2">
                                        {company.name}
                                        <br />
                                        <small>{company.owner}</small>
                                    </td>
                                    <td className="border-b px-4 py-2">{company.cap}</td>
                                    <td className="border-b px-4 py-2">{company.region}</td>
                                    <td className="border-b px-4 py-2">{company.province}</td>
                                    <td className="border-b px-4 py-2">{company.city}</td>
                                    <td className="border-b px-4 py-2">{company.category.name}</td>
                                    <td className="border-b px-4 py-2">
                                        <div className="flex items-center justify-end">
                                            {company.draftId && (
                                                <Link to={`/companies/draft/${company.draftId}`} className="button py-2 mr-2">
                                                    <Draft className="fill-current w-6 h-6" title="Bozza" />
                                                </Link>
                                            )}
                                            <Link to={`/companies/offers/${company.id}`} className="button bordered py-2">
                                                <Megaphone className="fill-current w-6 h-6" title="Offerte" />
                                            </Link>
                                            <Link to={`/companies/manage/${company.id}`} className="button green py-2 ml-2">
                                                <Edit className="fill-current w-6 h-6 text-white" title="Modifica" />
                                            </Link>
                                            <button className="button red py-2 ml-2" onClick={(): Promise<void> => del(company.id)}>
                                                <TrashCan className="fill-current w-6 h-6 text-white" title="Elimina" />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td className="px-4 py-2" colSpan={6}>
                                    Nessuna azienda inserita
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>

                <div className="mt-8 flex justify-center">
                    <div className="border border-gray-700 rounded flex">
                        {cursor && cursor > 0 ? (
                            <button className="block w-32 p-4 text-center font-semibold" onClick={(): void => sort(cursor - limit)}>
                                INDIETRO
                            </button>
                        ) : (
                            <></>
                        )}
                        {companies.data && companies.data.companyADList.pageInfo.hasNextPage && (
                            <button
                                className="even:border-l border-gray-700 block w-32 p-4 text-center font-semibold"
                                onClick={(): void => sort(companies.data.companyADList.pageInfo.endCursor)}
                            >
                                AVANTI
                            </button>
                        )}
                    </div>
                </div>

                <div className="mt-8 bg-gray-100 p-4 text-sm">
                    <b>Legenda delle icone nella pagina:</b>
                    <div className="flex items-center mt-2">
                        <EyeBall className="w-4 h-4 mr-1" /> Se verde, l&apos;azienda è visibile nel portale,
                        <Megaphone className="w-4 h-4 ml-1 mr-1" /> premette di visualizzare le offerte inserite per l&apos;azienda,
                        <Edit className="w-4 h-4 ml-1 mr-1" /> premette di modificare l&apos;azienda,
                        <Draft className="w-4 h-4 ml-1 mr-1" /> visualizza le modifiche in fase di approvazione, mentre
                        <TrashCan className="w-4 h-4 ml-1 mr-1" /> elimina l&apos;azienda definitivamente
                    </div>
                </div>
            </div>
        </Layout>
    );
};
