import { useContext } from 'react';
import { useHistory, RouteProps, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { AuthContext } from '../../contexts/AuthProvider';

import firebase from '../../firebase';

import { TokenManager } from '../../token';

import { Notification } from '../../notification';

export const Login: React.FC = (props: RouteProps) => {
    const authContext = useContext(AuthContext);
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();
    const history = useHistory();

    const onSubmit = async (form: Record<string, string>): Promise<void> => {
        try {
            const result = await firebase.auth().signInWithEmailAndPassword(form.email, form.password);
            await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);

            if (result.user) {
                if (authContext.setUser) {
                    authContext.setUser(result.user);
                }

                const token = await result.user.getIdToken();
                TokenManager.setToken(token);

                let returnUrl = undefined;
                if (props.location?.search) {
                    returnUrl = props.location.search.substring(props.location.search.lastIndexOf('=') + 1);
                }

                history.push(returnUrl ?? '/');
            }
        } catch (e) {
            Notification.error(e);
        }
    };

    return (
        <div className="h-full p-4 xl:p-8 flex flex-col items-center justify-center">
            <form noValidate onSubmit={handleSubmit(onSubmit)} className="w-full xl:w-1/4 md:w-4/6">
                <h1>Accedi</h1>
                <div className="mb-6">
                    <label className="label" htmlFor="email">
                        Email
                    </label>
                    <input
                        type="text"
                        {...register('email', {
                            required: "L'email è obbligatoria",
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: "L'indirizzo email deve essere nel formato mario@rossi.it"
                            }
                        })}
                        className="input"
                        placeholder="Email"
                        aria-label="Email"
                    />
                    <p className="mt-2 text-red-500 text-xs italic">{errors.email && errors.email.message}</p>
                </div>
                <div className="mb-6">
                    <label className="label" htmlFor="password">
                        Password
                    </label>
                    <input
                        type="password"
                        {...register('password', { required: 'La password è obbligatoria' })}
                        placeholder="Password"
                        className="input"
                        aria-label="Password"
                    />
                    <p className="mt-2 text-red-500 text-xs italic">{errors.password && errors.password.message}</p>
                </div>
                <div className="flex flex-col md:flex-row items-center justify-between ">
                    <button type="submit" className="button green w-full md:w-auto">
                        Accedi
                    </button>
                    <Link to="/auth/forgot" className="button mt-4 lg:mt-0">
                        Password dimenticata?
                    </Link>
                </div>
            </form>

            <Link to="/auth/register" className="mt-8 button text-base">
                Non hai un account? Registrati!
            </Link>
        </div>
    );
};
