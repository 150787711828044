import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useMutation } from 'urql';

import firebase from '../../firebase';

import { Notification } from '../../notification';

export const Register: React.FC = () => {
    const {
        register,
        watch,
        handleSubmit,
        formState: { errors }
    } = useForm();
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
    const [, sendRegistrationConfirm] = useMutation(
        `mutation($email: String!) {
            sendRegistrationConfirm(email: $email)
        }`
    );

    const onSubmit = async (form: Record<string, string>): Promise<void> => {
        try {
            await firebase.auth().createUserWithEmailAndPassword(form.email, form.password);
            await sendRegistrationConfirm({ email: form.email });

            setShowConfirmation(true);
        } catch (e) {
            Notification.error(e);
        }
    };

    return (
        <div className="h-full p-4 xl:p-8 flex flex-col items-center justify-center">
            {!showConfirmation ? (
                <form noValidate onSubmit={handleSubmit(onSubmit)} className="w-full xl:w-1/4 md:w-4/6">
                    <h1>Registrati</h1>
                    <div className="mb-6">
                        <label className="label" htmlFor="email">
                            Email
                        </label>
                        <input
                            type="text"
                            {...register('email', {
                                required: "L'email è obbligatoria",
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    message: "L'indirizzo email deve essere nel formato mario@rossi.it"
                                }
                            })}
                            className="input"
                            placeholder="Email"
                            aria-label="Email"
                        />
                        <p className="mt-2 text-red-500 text-xs italic">{errors.email && errors.email.message}</p>
                    </div>
                    <div className="mb-6">
                        <label className="label" htmlFor="password">
                            Password
                        </label>
                        <input
                            type="password"
                            {...register('password', {
                                required: 'La password è obbligatoria',
                                pattern: {
                                    value: /^.*(?=.{6,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1})/,
                                    message: 'La password non rispetta gli standard di sicurezza'
                                }
                            })}
                            className="input"
                            placeholder="Password"
                            aria-label="Password"
                        />
                        <p className="mt-2 text-red-500 text-xs italic">{errors.password && errors.password.message}</p>
                    </div>
                    <div className="mb-6">
                        <label className="label" htmlFor="confirmPassword">
                            Ripeti la password
                        </label>
                        <input
                            type="password"
                            {...register('confirmPassword', {
                                validate: value => value === watch('password') || 'Le password non combaciano'
                            })}
                            className="input"
                            placeholder="Ripeti la password"
                            aria-label="Ripeti la password"
                        />
                        <p className="mt-2 text-red-500 text-xs italic">{errors.confirmPassword && errors.confirmPassword.message}</p>
                    </div>

                    <button type="submit" className="button green w-full">
                        Registrati
                    </button>

                    <p className="mt-6 text-sm">
                        La password deve essere di almeno 6 caratteri e deve contenere una cifra, una lettera maiuscola e un carattere speciale (@, &, !, ?,
                        etc...)
                    </p>
                </form>
            ) : (
                <div className="w-full md:w-1/3 text-center">
                    <h1>Grazie per esserti registrato</h1>
                    <p className="text-xl">
                        Riceverai una email per confermare la tua iscrizione. Non appena verificato il tuo account, potrai accedere e inserire la tua azienda
                    </p>
                    <div className="mt-8">
                        <Link to="/" className="button">
                            Torna alla home page
                        </Link>
                    </div>
                </div>
            )}
        </div>
    );
};
