import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useMutation } from 'urql';

import { Notification } from '../../notification';

export const Forgot: React.FC = () => {
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
    const [, sendReset] = useMutation(
        `mutation($email: String!) {
            sendResetConfirm(email: $email)
        }`
    );

    const onSubmit = async (form: Record<string, string>): Promise<void> => {
        try {
            await sendReset({ email: form.email });
            setShowConfirmation(true);
        } catch (e) {
            Notification.error(e);
        }
    };

    return (
        <div className="h-full p-4 xl:p-8 flex flex-col items-center justify-center">
            {!showConfirmation ? (
                <>
                    <form noValidate onSubmit={handleSubmit(onSubmit)} className="w-full xl:w-1/4 md:w-4/6">
                        <h3>Password dimenticata?</h3>
                        <p>
                            Per motivi di privacy, non conosciamo la tua password. Prima di essere salvata nel nostro database viene criptata coi massimi
                            standard di sicurezza.
                            <br />
                            Se l&apos;hai dimenticata è necessario iniziare la procedura di recupero: inserisci qui sotto l&apos;indirizzo email con cui ti sei
                            iscritto al nostro sito e ti invieremo un messaggio con le istruzioni per iniziare la procedura di cambio password.
                        </p>

                        <div className="my-6">
                            <label className="label" htmlFor="email">
                                Email
                            </label>
                            <input
                                type="text"
                                {...register('email', {
                                    required: "L'email è obbligatoria",
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                        message: "L'indirizzo email deve essere nel formato mario@rossi.it"
                                    }
                                })}
                                className="input"
                                placeholder="Email"
                                aria-label="Email"
                            />
                            <p className="mt-2 text-red-500 text-xs italic">{errors.email && errors.email.message}</p>
                        </div>

                        <button type="submit" className="button green w-full">
                            Inizia il recupero
                        </button>
                    </form>

                    <Link to="/auth/login" className="mt-8 button text-base">
                        Torna al login
                    </Link>
                </>
            ) : (
                <div className="w-full md:w-1/3 text-center">
                    <h1>Reset effettuato</h1>
                    <p className="text-xl">Riceverai una email con il link per cambiare la password. Segui la procedura descritta e procedi al cambio!</p>
                    <Link to="/" className="block mt-8 button text-base">
                        Torna alla home
                    </Link>
                </div>
            )}
        </div>
    );
};
