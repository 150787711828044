import { useState, ReactElement, ReactNode, Dispatch, SetStateAction, useEffect, createContext } from 'react';

type ContextProps = {
    setIsLoading?: Dispatch<SetStateAction<boolean>>;
    children?: ReactNode;
};

export const LoadingContext = createContext<Partial<ContextProps>>({});

export const LoadingProvider: React.FC<ContextProps> = (props: ContextProps): ReactElement => {
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (isLoading) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [isLoading]);

    return (
        <LoadingContext.Provider value={{ setIsLoading }}>
            {isLoading && (
                <div
                    className="fixed inset-0 w-screen h-screen flex justify-center items-center"
                    style={{ backgroundColor: 'rgba(0, 0, 0, 0.7)', zIndex: 99999 }}
                >
                    <div className="spinner">
                        <div className="dot1"></div>
                        <div className="dot2"></div>
                    </div>
                </div>
            )}

            {props.children}
        </LoadingContext.Provider>
    );
};
