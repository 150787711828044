import { forwardRef } from 'react';

type Props = React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>
// eslint-disable-next-line react/display-name
export const Select = forwardRef<HTMLSelectElement, Props>((props, ref) => (
    <div className={`inline-block relative text-2xl w-full`}>
        <select
            ref={ref}
            {...props}
            className="block w-full appearance-none border-2 bg-gray-200 border-gray-200 p-4 pr-8 rounded text-xl text-gray-700 leading-tight hover:border-gray-500 focus:outline-none focus:bg-white focus:shadow-outline focus:border-pcons-green"
        />
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
        </div>
    </div>
));

/* import { forwardRef, RefForwardingComponent, ChangeEvent, ReactNode } from 'react';

type SelectProps = {
    name: string;
    className?: string;
    label?: string;
    children?: ReactNode;
    onChange?: (value?: string) => void;
};

const Select: RefForwardingComponent<HTMLSelectElement, SelectProps> = (props, ref) => {
    return (
        <div className={`inline-block relative text-2xl w-full ${props.className}`}>
            <select
                ref={ref}
                name={props.name}
                className="block w-full appearance-none border-2 bg-gray-200 border-gray-200 p-4 pr-8 rounded text-xl text-gray-700 leading-tight hover:border-gray-500 focus:outline-none focus:bg-white focus:shadow-outline focus:border-pcons-green"
                aria-label={props.label}
                onChange={(event: ChangeEvent<HTMLSelectElement>): void => props.onChange && props.onChange(event.target.value)}
            >
                {props.children}
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
            </div>
        </div>
    );
};

export default forwardRef(Select);
 */
