import { useContext, useState, useEffect, ChangeEvent } from 'react';
import { useQuery, useMutation } from 'urql';
import { useForm } from 'react-hook-form';
import { useParams, useHistory, Link } from 'react-router-dom';
import ReactQuill from 'react-quill';

import { LoadingContext } from '../../contexts/LoadingProvider';

import { Notification } from '../../notification';

import { Layout } from '../../components/_layout';
import { Select } from '../../components/select';
import { ReactComponent as Chevron } from '../../assets/left-chevron.svg';

import 'react-quill/dist/quill.snow.css';

const GETREGIONS = `query { regions }`;
const GETPROVINCES = `query($region: String!) { 
    provinces(region: $region) { 
        name code 
    } 
}`;

const GETCITIES = `query($provinceCode: String!) {
    cities(provinceCode: $provinceCode) {
        name
    }
}`;

const GETCATEGORIES = `query {
    categories(limit: 999, orderBy: order_ASC) {
        edges {
            slug
            name
        }
    }
}`;

const GETCOMPANY = `query($id: ID!) {
    company(id: $id) {
        name
        cap
        address
        city
        province
        region
        category {
            slug
        }
        description
    }
}`;

const GETDRAFT = `query($id: ID!) {
    getDraftByOwner(id: $id) {
        name
        cap
        address
        city
        province
        region
        category {
            slug
        }
        description
    }
}`;

export const ManageUserCompanyDraft: React.FC = () => {
    const { setIsLoading } = useContext(LoadingContext);
    const {
        register,
        handleSubmit,
        setValue,
        watch,
        formState: { errors }
    } = useForm();
    const history = useHistory();

    const { id } = useParams<{ id: string }>();
    let { draftId } = useParams<{ draftId: string }>();

    const [region, setRegion] = useState<string>();
    const [provinceCode, setProvinceCode] = useState<string>();

    const [regions] = useQuery({ query: GETREGIONS });
    const [provinces] = useQuery({ query: GETPROVINCES, variables: { region }, pause: !region });
    const [cities] = useQuery({ query: GETCITIES, variables: { provinceCode }, pause: !provinceCode });
    const [categories] = useQuery({ query: GETCATEGORIES });

    const [company] = useQuery({ query: GETCOMPANY, variables: { id }, pause: draftId !== undefined });
    const [draft] = useQuery({ query: GETDRAFT, variables: { id: draftId }, pause: !draftId });

    // COMPANY EFFECT
    // this effect is called when there is no draft so it gets the actual company data
    useEffect(() => {
        if (company.data) {
            setValue('name', company.data.company.name);
            setValue('cap', company.data.company.cap);
            setValue('address', company.data.company.address);
            setRegion(company.data.company.region);
            setValue('region', company.data.company.region);
            setProvinceCode(company.data.company.province);
            setTimeout(() => {
                setValue('province', company.data.company.province);
                setValue('city', company.data.company.city);
            }, 500);
            setValue('category', company.data.company.category.slug);
            setDescription(company.data.company.description);
        }
    }, [company.data, setValue]);

    // DRAFT EFFECT
    // this effect is called because draft data is present. Company fetching is not executed so the company effect does not trigger
    useEffect(() => {
        if (draft.data && draft.data.getDraftByOwner) {
            setValue('name', draft.data.getDraftByOwner.name);
            setValue('cap', draft.data.getDraftByOwner.cap);
            setValue('address', draft.data.getDraftByOwner.address);
            setRegion(draft.data.getDraftByOwner.region);
            setValue('region', draft.data.getDraftByOwner.region);
            setProvinceCode(draft.data.getDraftByOwner.province);
            setTimeout(() => {
                setValue('province', draft.data.getDraftByOwner.province);
                setValue('city', draft.data.getDraftByOwner.city);
            }, 500);
            setValue('category', draft.data.getDraftByOwner.category.slug);
            setDescription(draft.data.getDraftByOwner.description);
        }
    }, [draft.data, setValue]);

    const [description, setDescription] = useState<string>();

    const [image, setImage] = useState<string>();

    const [wCap, wAddress, wRegion, wProvince, wCity] = watch(['cap', 'address', 'region', 'province', 'city']);

    useEffect(() => {
        if (setIsLoading) {
            setIsLoading(regions.fetching || provinces.fetching || cities.fetching || categories.fetching || company.fetching || draft.fetching);
        }
    }, [setIsLoading, regions.fetching, provinces.fetching, cities.fetching, categories.fetching, company.fetching, draft.fetching]);

    useEffect(() => {
        setValue('description', description);
    }, [description, setValue]);

    const [, manageDraftByOwner] = useMutation(
        `mutation($id: ID, $draft: DraftInput!) {
            manageDraftByOwner(id: $id, draft: $draft)
        }`
    );

    const onSubmit = async (form: Record<string, string | number>): Promise<void> => {
        if (description) {
            form.description = description;
        }

        form.referenceCompany = id;

        const { data, error } = await manageDraftByOwner({ id: draftId, draft: form });

        if (data.manageDraftByOwner) {
            if (!draftId) {
                draftId = data.manageDraftByOwner;
                history.replace(`/user/draft/${id}/${draftId}`);
            }

            Notification.success('Dettagli salvati e inviati per approvazione');
        }

        if (error) {
            Notification.error(error);
        }
    };

    const [, deleteDraftByOwner] = useMutation(
        `mutation($id: ID!) {
            deleteDraftByOwner(id: $id)
        }`
    );

    const discard = async () => {
        const { data, error } = await deleteDraftByOwner({ id: draftId });

        if (data.deleteDraftByOwner) {
            Notification.success('Modifiche scartate');
            history.replace('/user/companies');
        }

        if (error) {
            Notification.error(error);
        }
    };

    const [, addCompanyImageByOwner] = useMutation(
        `mutation($id: ID!, $file: Upload!) {
            addCompanyImageByOwner(id: $id, file: $file)
        }`
    );

    const uploadImage = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
        event.preventDefault();

        const file = event.target.files && event.target.files[0];

        const { data, error } = await addCompanyImageByOwner({ id, file });

        if (data.addCompanyImageByOwner) {
            setImage(data.addCompanyImageByOwner);
        }

        if (error) {
            Notification.error(error);
        }
    };

    const [, removeCompanyImageByOwner] = useMutation(
        `mutation($id: ID!) {
            removeCompanyImageByOwner(id: $id)
        }`
    );

    const removeImage = async (): Promise<void> => {
        const { data, error } = await removeCompanyImageByOwner({ id });

        if (data.removeCompanyImageByOwner) {
            setImage(undefined);
        }

        if (error) {
            Notification.error(error);
        }
    };

    if (regions.error || provinces.error || cities.error || categories.error || company.error || draft.error) {
        Notification.error(regions.error || provinces.error || cities.error || categories.error || company.error || draft.error);
        history.replace('/user/companies');
        return <></>;
    }

    return (
        <Layout bodyClasses="p-4">
            <div className="flex items-center">
                <Link to="/user/companies" className="text-pcons-green text-3xl">
                    <Chevron className="fill-current h-8 w-8" />
                </Link>
                <h3 className="ml-4">{!id ? 'Crea nuova' : 'Modifica'} azienda</h3>
            </div>

            <div className="w-full xl:w-1/2 mx-auto flex flex-col md:flex-row">
                <form noValidate className="flex-1 p-4" onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-6">
                        <label className="label" htmlFor="name">
                            Nome Azienda
                        </label>
                        <input
                            type="text"
                            {...register('name', { required: "Il nome dell'azienda è obbligatorio" })}
                            className="input"
                            placeholder="Nome azienda"
                            aria-label="Nome azienda"
                        />
                        <p className="mt-2 text-red-500 text-xs italic">{errors.name && errors.name.message}</p>
                    </div>
                    <div className="flex flex-col md:flex-row">
                        <div className="mb-6 md:w-32 md:mr-2">
                            <label className="label" htmlFor="cap">
                                CAP
                            </label>
                            <input
                                type="text"
                                {...register('cap', { required: 'Il CAP è obbligatorio' })}
                                className="input"
                                placeholder="Indirizzo"
                                aria-label="Indirizzo"
                            />
                            <p className="mt-2 text-red-500 text-xs italic">{errors.cap && errors.cap.message}</p>
                        </div>
                        <div className="flex-1">
                            <label className="label" htmlFor="address">
                                Indirizzo
                            </label>
                            <input
                                type="text"
                                {...register('address', { required: "L'indirizzo è obbligatorio" })}
                                className="input"
                                placeholder="Indirizzo"
                                aria-label="Indirizzo"
                            />
                            <p className="mt-2 text-red-500 text-xs italic">{errors.address && errors.address.message}</p>
                        </div>
                    </div>

                    <div className="mb-6">
                        <label className="label" htmlFor="region">
                            Regione
                        </label>
                        <Select
                            {...register('region', { required: 'La regione è obbligatoria' })}
                            aria-label="Regione"
                            onChange={event => {
                                setRegion(event.target.value);
                                setProvinceCode(undefined);
                                setValue('province', undefined);
                                setValue('city', undefined);
                            }}
                        >
                            <option value="">...</option>
                            {regions.data?.regions &&
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                regions.data?.regions.map((r: string) => (
                                    <option key={r} value={r}>
                                        {r}
                                    </option>
                                ))}
                        </Select>
                        <p className="mt-2 text-red-500 text-xs italic">{errors.region && errors.region.message}</p>
                    </div>

                    <div className="mb-6">
                        <label className="label" htmlFor="province">
                            Provincia
                        </label>
                        <Select
                            {...register('province', { required: 'La provincia è obbligatoria' })}
                            aria-label="Provincia"
                            onChange={event => {
                                setProvinceCode(event.target.value);
                                setValue('city', undefined);
                            }}
                        >
                            <option value="">...</option>
                            {provinces.data?.provinces &&
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                provinces.data?.provinces.map((p: any) => (
                                    <option key={p.code} value={p.code}>
                                        {p.name}
                                    </option>
                                ))}
                        </Select>
                        <p className="mt-2 text-red-500 text-xs italic">{errors.province && errors.province.message}</p>
                    </div>

                    <div>
                        <label className="label" htmlFor="city">
                            Città
                        </label>
                        <Select {...register('city', { required: 'La città è obbligatoria' })} aria-label="Città">
                            <option value="">...</option>
                            {cities.data?.cities &&
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                cities.data?.cities.map((c: any) => (
                                    <option key={c.name} value={c.name}>
                                        {c.name}
                                    </option>
                                ))}
                        </Select>
                        <p className="mt-2 text-red-500 text-xs italic">{errors.city && errors.city.message}</p>
                    </div>
                    <div className="mb-6 mt-1 text-right text-sm font-bold text-pcons-green">
                        {wAddress && wCap && wCity && wProvince && wRegion && (
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={'https://www.google.com/maps?q=' + encodeURI(`${wAddress}, ${wCap} ${wCity} (${wProvince}) ${wRegion}`)}
                            >
                                Verifica l&apos;indirizzo con Google Maps &raquo;
                            </a>
                        )}
                    </div>

                    <div className="mb-6">
                        <label className="label" htmlFor="email">
                            Email azienda
                        </label>
                        <input type="text" {...register('email')} className="input" placeholder="Email azienda" aria-label="Email azienda" />
                    </div>

                    <div className="mb-6">
                        <label className="label" htmlFor="phone">
                            Telefono
                        </label>
                        <input type="text" {...register('phone')} className="input" placeholder="Telefono" aria-label="Telefono" />
                    </div>

                    <div className="mb-6">
                        <label className="label" htmlFor="category">
                            Classe merceologica
                        </label>
                        <Select {...register('category', { required: 'La classe merceologica è obbligatoria' })} aria-label="Classe merceologica">
                            <option value="">...</option>
                            {categories.data?.categories &&
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                categories.data?.categories.edges.map((c: any) => (
                                    <option key={c.slug} value={c.slug}>
                                        {c.name}
                                    </option>
                                ))}
                        </Select>
                        <p className="mt-2 text-red-500 text-xs italic">{errors.category && errors.category.message}</p>
                    </div>

                    <div className="mb-6">
                        <label className="label" htmlFor="description">
                            Descrizione
                        </label>
                        <ReactQuill theme="snow" value={description} onChange={setDescription}>
                            <div className="bg-gray-200 text-gray-700" style={{ fontFamily: 'Montserrat, sans-serif', fontSize: '1.25rem' }} />
                        </ReactQuill>
                        <div className="hidden">
                            <input
                                type="text"
                                {...register('description', {
                                    validate: value => {
                                        return value.replace(/(<([^>]+)>)/gi, '').length >= 150;
                                    }
                                })}
                                className="input"
                            />
                        </div>
                        <p className="mt-2 text-red-500 text-xs italic">
                            {errors.description && 'La descrizione è obbligatoria e deve contenere almeno 150 caratteri'}
                        </p>
                    </div>

                    <button type="submit" className="button green w-full">
                        Invia per approvazione
                    </button>

                    {draftId && (
                        <button type="button" className="mt-4 button red w-full" onClick={(): Promise<void> => discard()}>
                            Scarta modifiche
                        </button>
                    )}
                </form>

                {id && (
                    <div className="w-full md:w-1/3 lg:w-2/6 p-4">
                        <span className="label">Immagine</span>
                        {image ? (
                            <>
                                <div className="flex justify-center items-center">
                                    <img src={image} alt="Immagine azienda" title="Immagine azienda" />
                                </div>
                                <button type="button" className="button red mt-4 w-full" onClick={removeImage}>
                                    Elimina
                                </button>
                            </>
                        ) : (
                            <>
                                <input type="file" id="file" className="hidden" accept=".jpg,.png,.jpeg" onChange={uploadImage} />
                                <label
                                    htmlFor="file"
                                    className="mt-2 w-full h-64 border-4 border-dashed border-pcons-green rounded-lg flex items-center justify-center text-6xl text-pcons-green
                    cursor-pointer"
                                >
                                    +
                                </label>
                            </>
                        )}
                    </div>
                )}
            </div>
        </Layout>
    );
};
