import { useEffect, useState, Dispatch, SetStateAction, ReactNode, ReactElement, createContext } from 'react';
import firebase from '../firebase';

type ContextProps = {
    user?: firebase.User | null;
    isAdmin?: boolean;
    authenticated?: boolean;
    setUser?: Dispatch<SetStateAction<firebase.User | null>>;
    loadingAuthState?: boolean;
    children?: ReactNode;
};

export const AuthContext = createContext<Partial<ContextProps>>({});

export const AuthProvider: React.FC<ContextProps> = ({ children }: ContextProps): ReactElement => {
    const [user, setUser] = useState<firebase.User | null>(null);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [loadingAuthState, setLoadingAuthState] = useState(true);

    useEffect(() => {
        firebase.auth().onAuthStateChanged(async user => {
            setUser(user);
            const parsedToken = await user?.getIdTokenResult();
            setIsAdmin(parsedToken?.claims.admin || false);
            setLoadingAuthState(false);
        });
    }, []);

    return (
        <AuthContext.Provider
            value={{
                user,
                isAdmin,
                authenticated: user !== null,
                setUser,
                loadingAuthState
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
