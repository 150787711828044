import { useContext, ReactElement } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

import { AuthContext } from './contexts/AuthProvider';

interface PrivateRouteProps extends RouteProps {
    requireAdmin?: boolean;
}

export const PrivateRoute: React.FC<PrivateRouteProps> = (props: PrivateRouteProps): ReactElement => {
    const { authenticated, loadingAuthState, isAdmin } = useContext(AuthContext);

    if (loadingAuthState) {
        return (
            <div className="h-full flex justify-center items-center text-pcons-green">
                <h3>Caricamento...</h3>
            </div>
        );
    }

    if (authenticated) {
        if (props.requireAdmin && !isAdmin) {
            return <Redirect to={{ pathname: '/user/companies' }} />;
        }

        return <Route {...props} />;
    } else {
        // eslint-disable-next-line react/prop-types
        const renderComponent = (): ReactElement => <Redirect to={{ pathname: '/auth/login', state: { returnUrl: props.location?.pathname } }} />;
        return <Route {...props} component={renderComponent} render={undefined} />;
    }
};
