import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { PrivateRoute } from './PrivateRoutes';

import { Login } from './pages/auth/login';
import { Register } from './pages/auth/register';
import { Forgot } from './pages/auth/forgot';
import { Manage } from './pages/auth/manage';

import { Dashboard } from './pages/dashboard';
import { Categories } from './pages/categories';
import { ManageCategory } from './pages/categories/manage';
import { Companies } from './pages/companies';
import { ManageCompany } from './pages/companies/manage';
import { ManageDraft } from './pages/companies/draft';
import { Offers } from './pages/companies/offers';
import { Export } from './pages/export';

// for user
import { UserServices } from './pages/user/services';
import { UserCompanies } from './pages/user/companies';
import { CreateUserCompany } from './pages/user/create';
import { ManageUserCompanyDraft } from './pages/user/draft';
import { UserOffers } from './pages/user/offers';
import { ManageUserOffer } from './pages/user/manage-offer';
import { Contact } from './pages/user/contact';

import 'react-toastify/dist/ReactToastify.min.css';
import './tailwind.generated.css';

const App: React.FC = () => {
    return (
        <div className="h-full">
            <Router>
                <Switch>
                    <Route exact path="/auth/login" component={Login} />
                    <Route exact path="/auth/register" component={Register} />
                    <Route exact path="/auth/forgot" component={Forgot} />
                    <Route exact path="/auth/manage" component={Manage} />
                    <PrivateRoute exact path="/" component={Dashboard} requireAdmin={true} />
                    <PrivateRoute exact path="/categories" component={Categories} requireAdmin={true} />
                    <PrivateRoute exact path="/categories/manage/:id?" component={ManageCategory} requireAdmin={true} />
                    <PrivateRoute exact path="/companies" component={Companies} requireAdmin={true} />
                    <PrivateRoute exact path="/companies/manage/:id?" component={ManageCompany} requireAdmin={true} />
                    <PrivateRoute exact path="/companies/draft/:id" component={ManageDraft} requireAdmin={true} />
                    <PrivateRoute exact path="/companies/offers/:id" component={Offers} requireAdmin={true} />
                    <PrivateRoute exact path="/export" component={Export} requireAdmin={true} />
                    {/* User routes */}
                    <PrivateRoute exact path="/user/services" component={UserServices} />
                    <PrivateRoute exact path="/user/companies" component={UserCompanies} />
                    <PrivateRoute exact path="/user/create" component={CreateUserCompany} />
                    <PrivateRoute exact path="/user/draft/:id/:draftId?" component={ManageUserCompanyDraft} />
                    <PrivateRoute exact path="/user/offers/manage/:companyId/:id?" component={ManageUserOffer} />
                    <PrivateRoute exact path="/user/offers/:id" component={UserOffers} />
                    <PrivateRoute exact path="/contact/:kind" component={Contact} />
                </Switch>
            </Router>

            <ToastContainer
                key="ToastContainer-welcome"
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                draggable
                pauseOnHover
                style={{ width: '360px' }}
            />
        </div>
    );
};

export default App;
